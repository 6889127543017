import styled from "styled-components"

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  transition: all 200ms;

  &:hover {
    cursor: pointer;
    background: var(--panel-hover-color)
  }

  svg {
    font-size: 30px;
    color: inherit;
    vertical-align: center;
  }
`
