import React from "react"
import { Wrapper } from "./styles"

export function Button ({
  Icon,
  children,
  label,
  onClick,
}) {
  return (
    <Wrapper onClick={onClick}>
      {Icon && <Icon />}
      {label || children}
    </Wrapper>
  )
}

export default Button
