import * as queries from "queries/aspect_queries"
import { useMutation } from "@apollo/client"

const useAttachments = ({ getParams }) => {
  const [addMut] = useMutation(queries.ADD_ATTACHMENT_QUERY)

  const addToAspect = ({
    variables: {
      id,
      aspectId = id,
      cloudId: tempId,
      name,
      primary = false,
    },
  }) => {
    const { client } = getParams()

    const { attachments } = client.readFragment({
      id: `Aspect:${aspectId}`,
      fragment: queries.GET_ASPECT_WITH_TASKS,
      fragmentName: "AspectWithTasks",
    })

    client.writeFragment({
      id: `Aspect:${aspectId}`,
      fragment: queries.GET_ASPECT_WITH_TASKS,
      fragmentName: "AspectWithTasks",
      data: {
        attachments: [
          {
            __typename: "Attachment",
            id: tempId,
            label: name,
            aspectId,
            completed: null,
            description: null,
            mediaPath: null,
            taskId: null,
            state: null,
            primary,
            loading: true,
          },
          ...attachments,
        ],
      },
    })

    return ({ url }) => {
      addMut({
        variables: {
          label: name,
          mediaPath: url,
          aspectId: String(aspectId),
          primary,
        },
        update: (cache, { data: { addAttachment: attachment } }) => {
          cache.modify({
            id: `Aspect:${aspectId}`,
            fields: {
            // replace temporary "loading" attachment with new attachment and clean up
              attachments (existingAttachmentRefs = [], { readField }) {
                const newAttachmentRef = cache.writeFragment({
                  data: attachment,
                  fragment: queries.ATTACHMENT_FRAGMENT,
                })

                const updatedAttachmentRefs = existingAttachmentRefs.map(existingAttachmentRef => (
                  readField("id", existingAttachmentRef) === tempId ? newAttachmentRef : existingAttachmentRef)
                )

                cache.evict({ id: `Attachment:${tempId}` })

                return updatedAttachmentRefs
              },
            },
          })
        },
      })
    }
  }

  const addToAspectVars = (variables) => addToAspect({ variables })

  const addToTask = ({
    id,
    cloudId,
    name,
    primary,
    taskId = id,
  }) => {
    const { client } = getParams()

    const { attachments } = client.readFragment({
      id: `Task:${taskId}`,
      fragment: queries.TASK_WITH_ATTACHMENTS,
      fragmentName: "TaskFieldsWithAttachments",
    })

    client.writeFragment({
      id: `Task:${taskId}`,
      fragment: queries.TASK_WITH_ATTACHMENTS,
      fragmentName: "TaskFieldsWithAttachments",
      data: {
        attachments: [
          {
            id: cloudId,
            label: name,
            aspectId: null,
            completed: null,
            description: null,
            mediaPath: null,
            taskId,
            state: null,
            loading: true,
            primary: null,
            __typename: "Attachment",
          },
          ...attachments,
        ],
      },
    })

    return ({
      url,
    }) => {
      addMut({
        variables: {
          label: name,
          mediaPath: url,
          taskId,
          primary,
        },
        update: (cache, { data: { addAttachment: attachment } }) => {
          cache.modify({
            id: `Task:${taskId}`,
            fields: {
              attachments (existing = [], { readField }) {
                const newRef = cache.writeFragment({
                  data: attachment,
                  fragment: queries.ATTACHMENT_FRAGMENT,
                })

                return existing.map(r => (readField("id", r) === cloudId ? newRef : r))
              },
            },
          })
        },
      })
    }
  }

  const addToTaskVars = (variables) => addToTask({ variables })

  const add = (params) => {
    const { variables: { aspectId } } = params

    if (aspectId) {
      return addToAspect(params)
    }
    return addToTask(params)
  }

  const addVars = (variables) => add({ variables })

  const [update] = useMutation(queries.UPDATE_ATTACHMENT_QUERY)

  const updateVars = (variables) => update({ variables })

  // TODO: replace this with an update that has a call back to remove attachment from cache
  const [archive] = useMutation(queries.DELETE_ATTACHMENT_QUERY, {
    // onError,
    update (cache, { data: { deleteAttachment: attachment } }) {
      cache.evict({ id: `Attachment:${attachment.id}` })
      cache.gc()
    },
  })

  const archiveVars = (variables) => archive({ variables })

  const payload = {
    add,
    addVars,
    addToAspect,
    addToAspectVars,
    addToTask,
    addToTaskVars,
    update,
    updateVars,
    archive,
    archiveVars,
  }

  const curried = Object.keys(payload).reduce((acc, key) => {
    acc[key] = (args1) => (args2) => payload[key]({ ...args1, ...args2 })
    return acc
  }, {})

  return {
    ...payload,
    curried,
  }
}

export {
  useAttachments,
}
