import styled from "styled-components"
import Textfield from "components/inputs/Textfield"

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-content: center;
  align-items: center;
  height: 60px;
  padding: 0 15px;

  &:not(:last-child) {
    border-bottom: solid 1px var(--border-subdued-color);
  }

  &[data-css-group="true"] {
    height: 50px;
    padding-left: 20px;
  }
`

export const Image = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  margin-right: 5px;
  font-size: 18px;
  background-size: cover;
  border-style: solid;
  border-width: 2px;
  border-radius: 100%;
  color: var(--color);
`

export const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;

  svg {
    font-size: 20px;
  }
`

export const LabelTextField = styled(Textfield)`
  height: 100%;
  font-size: 16px;
`

export const Actions = styled.div`
  display: flex;
`

export const Action = styled.div`
  display:flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 30px;
  margin-left: 10px;
  line-height: 30px;
  border-radius: 4px;
  opacity: 0;

  &.active,
  &:hover {
    background: var(--panel-hover-color);
    opacity: 1;
  }

  ${/* sc-prop */ Wrapper}:hover & {
    opacity: 1;
  }

  svg {
    font-size: 16px;
    vertical-align:top;
  }
`

export const HoverIndicator = styled.div`
  line-height: 20px;
  width: 20px;
  height: 20px;
  position: absolute;
  right: -30px;
  top: 20px;
  opacity: 0;
  transition: all 200ms; 

  &[data-css-visible="true"] {
    right: -25px;
    opacity: 1;
  }

  svg {
    font-size: 20px;
    vertical-align: top;
  }
`
