import React, { forwardRef } from "react"
import startCase from "lodash/startCase"
import { Tooltip } from "components/Tooltip"
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline"
import {
  Wrapper,
  Heading,
  ErrorIconWrapper,
} from "./styles"

function Card ({
  animate,
  children,
  className,
  errors = [],
  heading,
  padded,
  subHeading,
  ...rest
}, ref) {
  const errorDetails = errors[0]?.details

  return (
    <Wrapper
      className={className}
      data-css-padded={padded}
      data-css-animate={animate}
      ref={ref}
      {...rest}
    >
      {errorDetails && (
        <Tooltip
          items={_keys(errorDetails).map(key => ({
            title: startCase(key),
            description: errorDetails[key].join(""),
          }))}
          error
        >
          <ErrorIconWrapper>
            <ErrorOutlineIcon />
          </ErrorIconWrapper>
        </Tooltip>
      )}

      {heading && <Heading>{heading}</Heading>}
      {children}
    </Wrapper>
  )
}

export default forwardRef(Card)
