import React, { useContext } from "react"
import { Section } from "components/Section"
import { Context as AppContext } from "AppContext"
import { Uploader } from "components/Uploader"
import { AssetList as AssetListComponent } from "components/AssetList"

function AssetList ({
  items,
  taskId,
}) {
  const { actions, menuOptions } = useContext(AppContext)

  return (
    <Uploader
      multiple
      onAdd={actions.attachment.curried.addToTaskVars({ id: taskId })}
    >
      {({ upload }) => (
        <Section
          headerOptions={{
            label: "Attachments",
            count: items.length,
            menu: {
              items: [
                menuOptions.archiveTaskAttachments({ taskId }),
                menuOptions.attachAsset({ upload }),
              ],
            },
            secondary: true,
          }}
          collapsable
        >
          <AssetListComponent
            taskId={taskId}
            items={items}
            collapsable
            secondary
          />
        </Section>
      )}
    </Uploader>

  )
}

export default AssetList
