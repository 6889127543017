/* TODO: the image preloading doesn't seem to work well, figure out what's going on */

import React, { useState, useRef } from "react"
import Loader from "components/Loader"
import StarsIcon from "@material-ui/icons/Stars"
import { MenuButton } from "components/MenuButton"
import Slideshow from "components/Lightbox"
import useToggle from "hooks/useToggle"
import {
  ImageWrapper,
  MenuButtonWrapper,
  Star,
  Wrapper,
} from "./styles"

function Image ({
  aspect,
  children,
  loading = false,
  menu,
  primary,
  size,
  source,
}) {
  const slideshow = useToggle(false)
  const [imageLoaded, setImageLoaded] = useState(false)
  const ref = useRef()

  return (
    <>
      <Wrapper
        data-css-aspect={aspect}
        data-css-size={size}
      >
        {!loading && menu?.items?.length > 0 && (
          <MenuButtonWrapper>
            <MenuButton {...menu} />
          </MenuButtonWrapper>
        )}
        {source && (
          <img
            ref={ref}
            alt="preload"
            src={source}
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              visibility: "hidden",
            }}
            onLoad={() => {
              setImageLoaded(true)
            }}
          />
        )}
        <Loader active={loading || (source && !imageLoaded)}>
          {source && (
            <ImageWrapper onClick={() => slideshow.on({ source })}>
              {source && <img alt="thumbnail" src={source} />}
            </ImageWrapper>
          )}

          {!source && children}

          {primary && (
            <Star>
              <StarsIcon />
            </Star>
          )}
        </Loader>
      </Wrapper>
      <Slideshow
        open={slideshow.isOn}
        image={slideshow?.props?.source}
        onClose={() => slideshow.off()}
      />
    </>
  )
}

export default Image
