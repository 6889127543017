import React from "react"
import {
  Wrapper,
  Label,
  Menu,
} from "./styles"

function ContextSelector ({
  activeProjectId,
  projects,
}) {
  return (
    <Wrapper>
      <Label>{projects.find(project => project.id === activeProjectId)?.label}</Label>
      <Menu />
    </Wrapper>
  )
}

export default ContextSelector
