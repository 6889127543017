import styled from "styled-components"

export const Wrapper = styled.div`
  display: flex;
  align-items: stretch;
  max-width: 100%;
`

export const Items = styled.div`
  display: flex;
  align-content: center;
  align-items: stretch;
  overflow: hidden;
  border: solid 3px black;
`

export const Item = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 20px;
  color: var(--text-subdued-color);
  cursor: default;
  font-size: 14px;
  line-height: 14px;
  flex: 0 1 auto;
  white-space: nowrap;
  //overflow: hidden;

  &:first-child {
    padding-left: 30px;
  }

  &:last-child {
    flex-basis: 2;
  }

  &:hover {
    text-decoration: underline;
  }

  &:not(:last-child) {
    &::before {
      position: absolute;
      left: 100%;
      display: block;
      width: 6px;
      height: 6px;
      margin-left: -4px;
      content: '';
      border: solid 1px #D4E0F4;
      border-top: none;
      border-left: none;
      transform: rotateZ(-45deg);
      flex: 1 0 auto
    }
  }

  &[data-css-active='true'] {
    color: var(--text-color);

    &:hover {
      text-decoration: none;
    }

    &::after {
      position: absolute;
      top: 100%;
      left: 50%;
      display: block;
      width: 6px;
      height: 6px;
      margin-top: -3px;
      margin-left: -4px;
      content: '';
      background: white;
      border: solid 1px var(--border-subdued-color);
      border-top: none;
      border-left: none;
      transform: rotateZ(45deg);
      flex: 1 0 auto
    }
  }

  span {
    display:block;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }
`
