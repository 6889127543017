import * as queries from "queries/aspect_queries"
import { useMutation } from "@apollo/client"

const useTasks = () => {
  // TODO: refactor this logic
  // consider getting a fresh order of tasks whenever a task is moved
  const [update] = useMutation(queries.UPDATE_TASK_QUERY, {
    update (cache, { data: { updateTask: updatedTask } }) {
      if (updatedTask.state === "archived") {
        cache.evict({ id: `Task:${updatedTask.id}` })
        cache.gc()
      }
    },
  })

  const updateVars = (variables) => update({ variables })

  const [add] = useMutation(queries.ADD_TASK_QUERY, {
    update: (cache, { data: { addTask: newTask } }) => {
      cache.modify({
        id: `Aspect:${newTask.aspectId}`,
        fields: {
          tasks: (cachedTasks) => cachedTasks.concat({ __ref: `Task:${newTask.id}` })
          ,
        },
      })
    },
  })

  // const newTaskRef = cache.writeFragment({
  // data: newTask,
  // fragment: gql`
  // fragment NewComment on Comment {
  // id
  // }
  // `,
  // })
  const addVars = (variables) => add({ variables })

  const archive = ({ variables, ...rest }) => {
    console.log(variables, rest)
    update({
      variables: {
        ...variables,
        state: "archived",
      },
      rest,
    })
  }

  const archiveVars = (variables) => archive({ variables })

  return {
    update,
    updateVars,
    add,
    addVars,
    archive,
    archiveVars,
  }
}

export {
  useTasks,
}
