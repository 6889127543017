import styled from "styled-components"
import CheckIcon from "@material-ui/icons/Check"

export const Wrapper = styled.div`
  position: relative;
  cursor: default;
  line-height: 20px;
`

export const ChoiceInput = styled.div.attrs(p => ({
  className: p.checked ? "checked" : "",
}))`

  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px; 
  vertical-align: middle;
  border: 1px solid var(--border-hard-color);
  border-radius: 10px;
  transition: all 200ms;

  &:not(:last-child) {
    margin-right: 10px;
  }

  & {
    ${p => p.checked && `
      background: var(--panel-subdued-color);
    `}

    ${p => !p.checked && `
      &:hover {
        background: var(--panel-hover-color);
      }
    `}
  }
`

export const Label = styled.div`
  ${p => p.deleted && `
    text-decoration: line-through
  `}
`

export const Checkmark = styled(CheckIcon)`
  position: absolute;
  top: 0;
  top: 1px;
  left: 1px;
  z-index: 10;
  font-size: 16px !important;
  color: var(--text-color);

  & {
    ${ChoiceInput}:not(.checked) & {
      display: none !important;
    }
  }
`
