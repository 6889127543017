import styled from "styled-components"

export const Wrapper = styled.div`
  svg {
    font-size: 20px; 
    vertical-align: middle;
  }

  &[data-css-has-tabs] {
    background: var(--panel-subdued-background-color)
  }
`

export const Layout = styled.div`
  display: flex;
  align-items: stretch;
  height: 40px;
`

Layout.Left = styled.div`
  display: flex;
  flex: 1 1 auto;
  overflow: hidden;
`

Layout.Right = styled.div`
  display: flex;
  flex: 0 0 auto;
  align-items: center;
`
