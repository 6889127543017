import React from "react"
import { useDrop } from "react-dnd"
// import { useDrag } from "react-dnd";

import { Wrapper } from "./styles"

function List ({ children, spacing }) {
  const [, dropRef] = useDrop({ accept: "ITEM" })

  return (
    <Wrapper ref={dropRef} data-spacing={spacing}>
      {children}
    </Wrapper>
  )
}

export default List
