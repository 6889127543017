import React from "react"
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp"
import {
  Wrapper,
  Layout,
  Heading,
} from "./styles"

function Options () {
  return (
    <Wrapper>
      <Layout>
        <Layout.Top>
          <Heading>Options</Heading>
          <ArrowDropUpIcon />
        </Layout.Top>
        <Layout.Main />
      </Layout>
    </Wrapper>
  )
}

export default Options
