// TODO: replace intepolated props with data-css attributes
import styled from "styled-components"

export const Wrapper = styled.div.attrs(p => ({
  className: [
    p.menuOpen ? "menuOpen" : "",
    p.active ? "active" : "",
  ].filter(Boolean).join(" "),
}))`
  display: flex;
  align-items: center;
  height: 30px;
  overflow: hidden;
  font-weight: normal;
  cursor: default;
  border: solid 1px transparent;
  border-radius: 4px;
  transition: all 200ms;
  transform-origin: top 100px;

  &.menuOpen,
  &:hover {
    background: var(--panel-subdued-background-color);
    border: solid 1px var(--border-subdued-color);
  }

  svg {
    font-size: 20px;
    vertical-align: middle;
  }

  & {
    ${p => p.visible === false && `
      height: 0;
      transform: scale(0);
      border-width: 0px;

      &:not(:last-child) {
         margin-bottom: 0 !important;
      }
   `}

    &[data-css-active="true"] {
     & {
       color: var(--panel-background-color);
       background-color: var(--text-color);
     }

     &.menuOpen,
     &:hover {
        border-color: transparent;
        background: var(--text-color);
     }
   }

    ${p => p.level && `
      padding-left:  ${((p.level || 0) * 10)}px;
    `}
  }
`

export const ArrowWrapper = styled.div`
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  border-radius: 4px;

  svg {
    font-size: 20px;
    vertical-align: top;
  }
`

export const ToggleButton = styled.div`
  position: relative;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  height: 20px;
  border: solid 1px transparent;

  &:hover ${ArrowWrapper} {
    border-color: #2B3D7F;
    opacity: 1;
  }
`

export const Label = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  align-self: stretch;
  padding-right: 10px;
  margin-left:5px;
  overflow: hidden;
  font-size: 14px;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const MenuButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 100%;
  margin-right: 5px;
  margin-left: auto;
  font-size: 20px;
  opacity: 0;

  ${/* sc-prop */ Wrapper}.menuOpen &,
  ${/* sc-prop */ Wrapper}:hover & {
    color: var(--text-subdued-color);
    opacity: 1;
  }

  ${/* sc-prop */ Wrapper}.menuOpen &,
  ${/* sc-prop */ Wrapper} &:hover {
    color: var(--text-color);
    opacity: 1;
  }

  ${/* sc-prop */ Wrapper}.menuOpen.active &,
  ${/* sc-prop */ Wrapper}.active &:hover {
    color: var(--panel-background-color);
  }

  svg {
    font-size: 16px;
  }
`

export const Between = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  align-content: center;
  align-items: center;
  height: 10px;

  &:after {
    display: block;
    flex: 1 1 auto;
    height: 0px;
    content: '';
    border-bottom: solid 1px #D4E0F4;
    box-shadow: none;
    opacity: 0;
    transition: all 100ms;
    transition-delay: 50ms;
  }

  &.active:after,
  &:hover:after {
    opacity: 1;
    transform: scaleX(1);
  }
`
