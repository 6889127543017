// TODO: improve error messaging
import React from "react"
import gql from "graphql-tag"
import * as Yup from "yup"
import { useMutation } from "@apollo/client"
import { Link, useNavigate } from "react-router-dom"
import { Formik } from "formik"
import { Form } from "components/Form"
import { Textfield } from "components/Form/Textfield/formik"
import { Card, CardLinks } from "components/Card"
import { GatewayPage } from "components/GatewayPage"

const SIGNUP_MUTATION = gql`
  mutation SignUp(
    $firstName: String!,
    $lastName: String!,
    $email: String!,
    $password: String!
    $inviteCode: String!
  ) {
    signup(
      firstName: $firstName
      lastName: $lastName 
      email: $email 
      password: $password
      inviteCode: $inviteCode
    ) {
      token
      user {
        email
      }
    }
  }
`

const schema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, "Too Short!")
    .max(70, "Too Long!")
    .required("Required"),
  lastName: Yup.string()
    .min(2, "Too Short!")
    .max(70, "Too Long!")
    .required("Required"),
  email: Yup.string()
    .email("Invalid email")
    .required("Required"),
  password: Yup.string()
    .min(6, "Too Short!")
    .max(70, "Too Long!")
    .required("Required"),
  passwordConfirm: Yup.string().when("password", {
    is: val => (!!(val && val.length > 0)),
    then: Yup.string().required("Required").oneOf(
      [Yup.ref("password")],
      "Both password need to be the same"
    ),
  }),
  inviteCode: Yup.string()
    .length(11, "This field has to be exactly 4 characters!")
    .required("Required"),
})

function SignupPage () {
  const navigate = useNavigate()

  const [signup] = useMutation(SIGNUP_MUTATION, {
    onCompleted: (data) => {
      localStorage.setItem("auth-token", data.signup.token)
      navigate("/project/dashboard")
    },
  })

  return (
    <GatewayPage title="Signup">
      <Formik
        validationSchema={schema}
        initialValues={{
          firstName: "",
          lastName: "",
          password: "",
          passwordConfirm: "",
          email: "",
          inviteCode: "",
        }}
        validateOnBlur={false}
        onSubmit={(variables, { setErrors, setStatus }) => {
          signup({ variables }).catch((error) => {
            /* TODO: re-enable field-level errors on submit
            const errorDetails = error.graphQLErrors?.[0]?.details
            console.log(errorDetails)
            const errors = (_keys(errorDetails).map(key => ({
              [key]: errorDetails[key].join(" "),
            })))
            setErrors(errors[0])
            */
            setStatus(error)
          })
        }}
      >
        {({ errors, status, touched }) => (
          <>
            <Card style={{ width: 360 }} errors={status?.graphQLErrors} padded animate>
              <Card.Heading state={status?.message && "error"}>
                {status?.message ? status.message : "Signup for an account"}
              </Card.Heading>
              <Form>
                <Form.Rows>
                  <Form.Row>
                    <Form.Col>
                      <Textfield
                        name="firstName"
                        error={touched.firstName && errors.firstName}
                        placeholder="First Name"
                        autoFocus
                      />
                    </Form.Col>
                    <Form.Col>
                      <Textfield
                        name="lastName"
                        error={touched.lastName && errors.lastName}
                        placeholder="Last Name"
                      />
                    </Form.Col>
                  </Form.Row>
                  <Form.Row>
                    <Form.Col>
                      <Textfield
                        name="email"
                        error={touched.email && errors.email}
                        placeholder="Email"
                      />
                    </Form.Col>
                  </Form.Row>
                  <Form.Row>
                    <Form.Col>
                      <Textfield
                        type="password"
                        name="password"
                        error={touched.password && errors.password}
                        placeholder="Password"
                      />
                    </Form.Col>
                    <Form.Col>
                      <Textfield
                        type="password"
                        name="passwordConfirm"
                        error={touched.passwordConfirm && errors.passwordConfirm}
                        placeholder="Confirm Password"
                      />
                    </Form.Col>
                  </Form.Row>
                  <Form.Row>
                    <Form.Col>
                      <Textfield
                        type="inviteCode"
                        name="inviteCode"
                        error={touched.inviteCode && errors.inviteCode}
                        placeholder="Invite Code"
                      />
                    </Form.Col>
                  </Form.Row>
                </Form.Rows>
                <Form.Actions>
                  <Form.Action>
                    <Form.Button type="submit">Signup</Form.Button>
                  </Form.Action>
                </Form.Actions>
              </Form>
            </Card>
            <CardLinks>
              <Link to="/auth/login">Have an account?</Link>
            </CardLinks>
          </>
        )}
      </Formik>
    </GatewayPage>
  )
}

export default SignupPage
