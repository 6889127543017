import React, { useState, useEffect, useMemo, useRef } from "react"
import { useDrag, useDrop } from "react-dnd"
import { getEmptyImage } from "react-dnd-html5-backend"
import { mergeRefs } from "lib/helpers"
import { Item as Wrapper } from "./styles"

function Item ({
  attention,
  children,
  className,
  clone,
  dropTarget,
  isDragging,
  isHoveredOver,
  width,
}) {
  return (
    <Wrapper
      isHoveredOver={isHoveredOver}
      width={width}
      dropTarget={dropTarget}
      clone={clone}
      isDragging={isDragging}
      attention={attention}
      className={className}
    >
      {children}
    </Wrapper>
  )
}

function SortableItem ({
  children,
  dndType,
  dropTarget,
  findItem,
  id,
  index,
  moveItem,
  onSort,
}) {
  const ref = useRef()
  const [itemWidth, setItemWidth] = useState()
  const [lastItemSwappedWith, setLastItemSwappedWith] = useState()

  useEffect(() => {
    if (ref.current) {
      setItemWidth(ref.current.clientWidth)
    }
  }, [ref.current])

  const clone = useMemo(() => (
    <Wrapper width={itemWidth} clone>
      {children}
    </Wrapper>
  ), [itemWidth])

  const [collectedProps, dragRef, preview] = useDrag({
    type: dndType,
    item: {
      id,
      clone: () => clone,
      originalIndex: index,
      setLastItemSwappedWith,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (_dropResult, monitor) => {
      const thisItem = monitor.getItem()
      const itemHasBeenDroppedAlready = monitor.didDrop()

      if (itemHasBeenDroppedAlready) {
        onSort(thisItem.id, lastItemSwappedWith.id)
      } else {
        moveItem(thisItem.id, thisItem.originalIndex)
      }
    },
  })

  const [, dropRef] = useDrop({
    accept: dndType,
    canDrop: () => true,
    hover (draggedItem,) {
      if (draggedItem.id !== id) {
        const { index: thisItemIndex } = findItem(id)
        moveItem(draggedItem.id, thisItemIndex)
        draggedItem.setLastItemSwappedWith({ id })
      }
    },
  })

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true })
  }, [])

  return (
    <Wrapper
      ref={mergeRefs(dragRef, dropRef, ref)}
      dropTarget={dropTarget}
      isDragging={collectedProps.isDragging}
    >
      {children}
    </Wrapper>
  )
}

function ListItem ({
  children,
  className,
  dndType,
  dropTarget,
  findItem,
  id,
  index,
  moveItem,
  onSort,
  sortable,
}) {
  if (sortable) {
    return (
      <SortableItem
        dndType={dndType}
        dropTarget={dropTarget}
        findItem={findItem}
        id={id}
        index={index}
        moveItem={moveItem}
        className={className}
        onSort={onSort}
      >
        {children}
      </SortableItem>
    )
  }
  return <Item className={className}>{children}</Item>
}
export default ListItem
