import React from "react"
import { useDragLayer } from "react-dnd"
// import { ItemTypes } from "./ItemTypes";
// import { BoxDragPreview } from "./BoxDragPreview";
// import { snapToGrid } from "./snapToGrid";

const layerStyles = {
  position: "fixed",
  pointerEvents: "none",
  zIndex: 10000,
  left: 0,
  top: 0,
  width: "100%",
  height: "100%",
}

function getItemStyles (initialOffset, currentOffset, isSnapToGrid) {
  if (!initialOffset || !currentOffset) {
    return {
      display: "none",
    }
  }

  /*
  let { x, y } = currentOffset

  if (isSnapToGrid) {
    x -= initialOffset.x
    y -= initialOffset.y
    // [x, y] = snapToGrid(x, y);
    x += initialOffset.x
    y += initialOffset.y
  }
  */

  const transform = `translate(${initialOffset.x}px, ${currentOffset.y}px)`

  return {
    transform,
    WebkitTransform: transform,
  }
}

function CustomDragLayer ({
  snapToGrid,
}) {
  const {
    currentOffset,
    initialOffset,
    isDragging,
    item,
    // itemType,
  } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }))

  function renderItem () {
    return item.clone()
    // console.log(item.other());
    // return <span>test</span>;
    // switch (itemType) {
    // case ItemTypes.BOX:
    // return <BoxDragPreview title={item.title} />;
    // default:
    // return null;
    // }
  }

  if (!isDragging) {
    return null
  }

  return (
    <div style={layerStyles}>
      <div style={getItemStyles(initialOffset, currentOffset, snapToGrid)}>
        {renderItem()}
      </div>
    </div>
  )
}

export default CustomDragLayer
