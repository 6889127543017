import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined"
import FormatListNumberedIcon from "@material-ui/icons/FormatListNumbered"
import ScheduleIcon from "@material-ui/icons/Schedule"
import FavoriteBorderOutlinedIcon from "@material-ui/icons/FavoriteBorderOutlined"
// import KeyboardArrowDownOutlinedIcon from "@material-ui/icons/KeyboardArrowDownOutlined"
import { duration } from "lib/helpers"

export const makeActions = (aspect, { navigation }) => [
  {
    id: "task_count",
    description: "The number of tasks completed.",
    label: `${aspect.meta.tasks.completed} of ${aspect.tasks.length}`,
    Icon: FormatListNumberedIcon,
    tooltip: {
      items: [{
        title: "Tasks Completed",
        description: "The number of tasks completed for this Aspect.",
      }],
    },
  }, {
    id: "estimate",
    description: "The total time estimate of all tasks.",
    label: duration(aspect.meta.tasks.estimate, 0) || "???",
    Icon: ScheduleIcon,
    tooltip: {
      items: [{
        title: "Time Estimate",
        description: [
          "The total time estimated to complete all tasks for this Aspect.",
          aspect.meta.tasks.estimate === 0 && "You haven't estimated any of the tasks. Tik-tok motherfucker.",
        ].filter(Boolean).join(" "),
      }],
    },
  }, {
    id: "info",
    description: "",
    Icon: InfoOutlinedIcon,
    tooltip: {
      items: [{
        title: "Date Created",
        description: new Date(aspect.dateCreated).toLocaleString(),
      }, {
        title: "Last Updated",
        description: new Date(aspect.dateUpdated).toLocaleString(),
      }],
    },
  }, {
    id: "add_favorite",
    Icon: FavoriteBorderOutlinedIcon,
    enabled: false,
  }, /* [{    id: "minimize",
    description: "Minimize this Aspect.",
    Icon: KeyboardArrowDownOutlinedIcon,
    enabled: false,
  }] */
]
