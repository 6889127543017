import React, { forwardRef, useContext } from "react"
import MoreVert from "@material-ui/icons/MoreVert"
import ArrowBackIcon from "@material-ui/icons/ArrowBack"
import FolderOpenIcon from "@material-ui/icons/FolderOpen"
import ColorHash from "color-hash"
import { PopMenu } from "components/PopMenu"
import { Context } from "AppContext"

import {
  Wrapper,
  Image,
  Icon,
  LabelTextField,
  Actions,
  Action,
  HoverIndicator,
} from "./styles"

const Header = forwardRef(({
  aspect,
  hovering,
  onLabelEditBegin,
  onLabelEditEnd,
}, ref) => {
  const color = new ColorHash({
    lightness: 0.7,
    saturation: 0.3,
  }).hex(aspect.id)

  const {
    actions,
    menuOptions,
    navigation,
  } = useContext(Context)

  return (
    <Wrapper data-css-type={aspect.type}>
      {aspect.type === "aspect" && (
        <Image style={{ "--color": color }}>
          {aspect.label[0]}
        </Image>
      )}

      {aspect.type === "group" && (
        <Icon>
          <FolderOpenIcon />
        </Icon>
      )}

      <LabelTextField
        id={`${aspect.id}_aspect_board_textfield`}
        value={aspect.label}
        ref={ref}
        doubleClickToEdit
        onBlur={onLabelEditEnd}
        onCancel={onLabelEditEnd}
        onFocus={onLabelEditBegin}
        onClick={() => {
          if (/group|project/.test(aspect.type)) {
            navigation.aspectDetail(aspect)
          } else {
            navigation.aspectDetail(aspect)
          }
        }}
        onSubmit={(label) => actions.aspect.updateVars({
          id: aspect.id,
          label,
        })}
      />
      {/*
      <div style={{ fontSize: 12 }}>
        {aspect.position} - {aspect.id}
      </div>
      */}
      <Actions>
        <PopMenu items={[menuOptions.archiveAspect(aspect)]}>
          <Action>
            <MoreVert />
          </Action>
        </PopMenu>
      </Actions>
      <HoverIndicator data-css-visible={hovering}>
        <ArrowBackIcon />
      </HoverIndicator>
    </Wrapper>
  )
})

export default Header
