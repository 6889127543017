import React from "react"
// import AddProject from "./sections/Security"
import gql from "graphql-tag"
import * as Yup from "yup"
import { useMutation } from "@apollo/client"
import { Formik } from "formik"
import { Form } from "components/Form"
import { Textfield } from "components/Form/Textfield/formik"
import { Modal, Content } from "./styles"

const CREATE_PROJECT_MUTATION = gql`
    mutation AddAspect(
      $label: String!,
      $description: String,
  ) {
    addAspect(
      label: $label
      description: $description
      type: "project"
    ) {
      id
      label
      description
    }
  }
`

const schema = Yup.object().shape({
  label: Yup.string()
  .min(2, "Too Short!")
  .max(70, "Too Long!")
  .required("Required"),
  description: Yup.string()
  .min(2, "Too Short!")
  .max(600, "Too Long!"),
})

function AddProject ({
  onClose: handleClose,
}) {
  const [createProject] = useMutation(CREATE_PROJECT_MUTATION, {
    refetchQueries: ["GetProjects"],
    onCompleted: () => {
      handleClose()
    },
  })
  return (
    <Modal
      breadcrumbs={[{
        label: "Create New Project",
        active: true,
      }]}
      onClose={handleClose}
    >
      <Content>
        <Formik
          validationSchema={schema}
          initialValues={{
            label: "",
            description: "",
          }}
          validateOnBlur={false}
          onSubmit={(variables, { setStatus }) => {
            createProject({ variables }).catch((error) => {
              setStatus(error.message)
            })
          }}
        >
          {({ errors, status, touched }) => (
            <Form>
              <Form.Rows>
                <Form.Row>
                  <Form.Col>
                    <Textfield
                      name="label"
                      error={touched.label && errors.label}
                      placeholder="Project Name"
                      autoFocus
                    />
                  </Form.Col>
                </Form.Row>
                <Form.Row>
                  <Form.Col>
                    <Textfield
                      name="description"
                      error={touched.description && errors.description}
                      placeholder="Project Description"
                      rows={12}
                      multiline
                    />
                  </Form.Col>
                </Form.Row>
              </Form.Rows>
              <Form.Actions>
                <Form.Action>
                  <Form.Button type="submit">Create Project</Form.Button>
                </Form.Action>
              </Form.Actions>
            </Form>
          )}
        </Formik>
      </Content>
    </Modal>
  )
}

export default AddProject
