import React from "react"
import Overlay from "components/Overlay"
import {
  Image,
  Wrapper,
  ImageWrapper,
} from "./styles"

function SlideshowModule ({
  image,
  onClose,
  open,
}) {
  if (!open) return null

  return (
    <Overlay onHide={onClose}>
      <Wrapper>
        <ImageWrapper>
          <Image src={image} onClick={onClose} />
        </ImageWrapper>
      </Wrapper>
    </Overlay>
  )
}

export default SlideshowModule
