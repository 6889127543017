import React, { useState } from "react"
import { useDrop } from "react-dnd"

function ListItemDropTarget ({
  greedy = false,
}) {
  const [, setHasDropped] = useState(false)

  const [{ isOverCurrent }, drop] = useDrop({
    accept: "ITEM",
    drop (_item, monitor) {
      const didDrop = monitor.didDrop()
      if (didDrop && !greedy) {
        return
      }
      setHasDropped(true)
      // setHasDroppedOnChild(didDrop);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      isOverCurrent: monitor.isOver({ shallow: false }),
      // isOverCurrent: hover,
    }),
  })

  return (
    <div
      ref={drop}
      style={{
        height: 60,
        background: "red",
      }}
    >
      {isOverCurrent ? "yes" : "no" }
    </div>
  )
}

export default ListItemDropTarget
