import React from "react"
import Items from "./Items"
import { ItemsWrapper } from "./styles"

function AspectExplorer ({
  aspects = [],
  activeId,
  onAspectBeginEdit,
  filter,
}) {
  return (
    <ItemsWrapper>
      <Items
        aspects={aspects}
        activeId={activeId}
        onAspectBeginEdit={onAspectBeginEdit}
        filter={filter}
      />
    </ItemsWrapper>
  )
}

export default AspectExplorer
