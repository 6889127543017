import styled from "styled-components"

export const Wrapper = styled.div`
  display: flex;
  height: 100%;
  color: var(--text-color);
  background: var(--panel-subdued-background-color);
  border-right: solid 1px var(--border-subdued-color);
`

export const Layout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`

Layout.Top = styled.div`
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  justify-content: center;
  height: 60px;
  border-bottom: solid 1px var(--border-subdued-color);
`

Layout.Menu = styled.div`
  flex: 0 0 auto;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: solid 1px var(--border-subdued-color);
`

Layout.Middle = styled.div`
  flex: 1 1 auto;
  overflow: hidden;
`

Layout.Bottom = styled.div`
  flex: 0 0 auto;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: solid 1px var(--border-subdued-color);
`

export const Logo = styled.a`
  display: block;
  width: 30px;
  height: 30px;

  img {
    width: 100%;
    height: 100%;
  }
`

export const Menu = styled.div`
  height: 50px;
`

export const Items = styled.div`
  max-height: 100%;
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }
`

export const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 4px;
  transition: all 200ms;

  &:hover,
  &[data-css-active="true"] {
    background: var(--button-color);
    color: var(--button-text-color);
  }

  svg {
    font-size: 24px;
  }
`
