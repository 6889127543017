import styled from "styled-components/macro"

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background: #F0F4FD;
  flex: 1 1 auto;
  z-index: 300;
  position: relative;
`

export const Layout = styled.div`
  display: inline-flex;
  flex-direction: column;
  flex-grow: 1;
`

Layout.Top = styled.div``

Layout.Bottom = styled.div`
  position: relative;
  flex-grow: 1;
  overflow: hidden;
`

export const Box = styled.div`
  content: '';
  display: block;
  position: absolute;
  top: 109px;
  left: -1px;
  width: 100%;
  bottom: 0;
  right: 0;
  z-index: 300;
  border-left: 1px solid var(--border-hard-color);
  border-top: 1px solid var(--border-hard-color);
  box-shadow: inset 0 0 8px 0 rgba(176,201,250,0.29);
  pointer-events: none;
`
