import React from "react"
import { Wrapper, Items, Item } from "./styles"

function Breadcrumb ({
  items = [],
}) {
  return (
    <Wrapper>
      <Items>
        {items.map(item => (
          <Item
            key={item.label}
            active={item.active}
            data-css-active={!!item.active}
            onClick={item.onClick}
          >
            <span>
              {item.label}
            </span>
          </Item>
        ))}
      </Items>
    </Wrapper>
  )
}

export default Breadcrumb
