import React from "react"
import DeleteIcon from "@material-ui/icons/Close"
import Loader from "components/Loader"
import {
  Layout,
  Card,
  Content,
  Overlay,
} from "./styles"
import Header from "./Header"

function Modal ({
  children,
  onClose,
  open = true,
  actions = [],
  breadcrumbs = [],
  tabs = [],
  className,
  loading,
}) {
  const enhancedActions = [...actions]

  if (onClose) {
    enhancedActions.push({
      id: "close",
      description: "Close this modal.",
      Icon: DeleteIcon,
      onClick: onClose,
    })
  }

  return (
    <Overlay
      onHide={onClose}
      data-css-open={open}
    >
      <Content className={className}>
        <Card>
          <Loader active={loading}>
            <Layout>
              <Layout.Top>
                <Header
                  breadcrumbs={breadcrumbs}
                  tabs={tabs}
                  actions={enhancedActions}
                />
              </Layout.Top>
              <Layout.Bottom>
                {children}
              </Layout.Bottom>
            </Layout>
          </Loader>
        </Card>
      </Content>
    </Overlay>
  )
}

export default Modal
