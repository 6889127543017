import React from "react"

import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import { ThemeProvider } from "styled-components"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import AppPage from "./pages/AppPage"
import { LoginPage } from "./pages/LoginPage"
import { SignupPage } from "./pages/SignupPage"
import StartPage from "./pages/StartPage"
import { DashboardPage } from "./pages/DashboardPage"
import CustomDragLayer from "./components/dnd/CustomDragLayer"
import "./assets/App.scss"
import * as theme from "./theme"

document.addEventListener("mousemove", (e) => {
  window.currentMouseX = e.pageX
  window.currentMouseY = e.pageY
})

function App () {
  return (
    <DndProvider backend={HTML5Backend}>
      <ThemeProvider theme={theme}>
        <>
          <CustomDragLayer />
          <Router>
            <Routes>
              <Route path="/project/dashboard" element={<DashboardPage />} />
              <Route path="/auth/login" element={<LoginPage />} />
              <Route path="/auth/signup" element={<SignupPage />} />
              <Route path="/project/start" element={<StartPage />} />
              <Route path="/project/:projectId" element={<AppPage />} />
              <Route path="/project/:projectId/:aspectId" element={<AppPage />} />
              <Route path="/project/:projectId/:aspectId/:action/:viewId" element={<AppPage />} />
              <Route path="/project/:projectId/:aspectId/:action" element={<AppPage />} />
              <Route path="/" element={<AppPage />} />
            </Routes>
          </Router>
        </>
      </ThemeProvider>
    </DndProvider>
  )
}

window.speechSynthesis.getVoices()

export default App
