import styled from "styled-components"

export const Wrapper = styled.div`
  height: 50px;
  border-bottom: solid 1px var(--border-subdued-color);
`

export const Layout = styled.div`
  display: flex;
  flex: 1 0 auto;
  height: 100%;
`

Layout.Left = styled.div`
  display: flex;
  flex: 1 1 auto;
  justify-content: stretch;
`

Layout.Right = styled.div`
  display: flex;
  flex: 0 0 auto;
  align-items: stretch;
`

export const Input = styled.input.attrs({
  type: "text",
})`
  flex: 1;
  padding: 0 20px;
  font-size: 14px;
  color: var(--text-color);

  &::placeholder {
    color: var(--text-subdued-color);
    font-weight: 300 !important;
  }
`

export const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  color: var(--text-subdued-color);

  svg {
    font-size: 18px;
  }
`
