/* eslint-disable  react/jsx-no-bind */
// TODO: improve animation timing for card
import React, { useLayoutEffect, useContext, useRef } from "react"
import useToggle from "hooks/useToggle"
import { AspectBoardContext } from "components/AspectBoard/Context"
import { PopMenu } from "components/PopMenu"
import { Context as AppContext } from "AppContext"
import { LaneButton } from "components/AspectBoard/Lanes/Lane/LaneButton"
import { Header } from "./Header"
import { TaskList } from "./TaskList"

import {
  Card,
  Between,
  Wrapper,
  Children,
} from "./styles"

function AspectCard ({
  aspect,
  displayTasks = true,
  index,
  itemNumber = 0,
  lastInGroup,
  level = 0,
  onBetweenEnter,
  onBetweenExit,
  top,
}) {
  const headerRef = useRef()
  const treeRef = useRef()
  const editingToggler = useToggle()
  const hoverToggler = useToggle()
  const { actions, menuOptions } = useContext(AppContext)
  const { aspectIdPendingFocus, setAspectIdPendingFocus } = useContext(AspectBoardContext)

  useLayoutEffect(() => {
    if (headerRef.current && aspectIdPendingFocus === aspect.id) {
      headerRef.current.focus()
      headerRef.current.setRange()

      // TODO: stop this from triggering a rerender
      setAspectIdPendingFocus(null)
    }
  }, [aspectIdPendingFocus])

  const afterAddAspect = ({ id }) => setAspectIdPendingFocus(id)

  return (
    <>
      {index === 0 && (
        <PopMenu
          anchor="mouseX"
          items={[
            menuOptions.addAspect({
              target: { id: aspect.parentId },
              position: 0,
              callback: afterAddAspect,
            }),
            menuOptions.addAspect({
              target: { id: aspect.parentId },
              position: 0,
              type: "group",
              callback: afterAddAspect,
            }),
          ]}
        >
          <Between
            data-css-top={top}
            onMouseEnter={onBetweenEnter}
            onMouseLeave={onBetweenExit}
          />
        </PopMenu>
      )}
      <Wrapper
        data-css-type={aspect.type}
        data-css-hovering={hoverToggler.isOn}
        data-css-level={level}
        ref={treeRef}
        style={{
          "--animation-delay": `${((itemNumber + 1 + index) * 100)}ms`,
        }}
      >
        <Card
          style={{
            "--level": level,
          }}
          data-css-editing={editingToggler.isOn}
          data-css-type={aspect.type}
        >
          <Header
            ref={headerRef}
            aspect={aspect}
            hovering={hoverToggler.isOn}
            onLabelEditBegin={editingToggler.on}
            onLabelEditEnd={editingToggler.off}
          />
          {aspect.type === "aspect" && displayTasks && (
            <TaskList
              tasks={aspect.tasks}
              onTaskEditBegin={editingToggler.on}
              onTaskEditEnd={editingToggler.off}
              onTaskAdd={({ description }) => {
                actions.task.addVars({
                  aspectId: aspect.id,
                  description,
                })
              }}
            />
          )}
        </Card>
        {aspect.type === "group" && (
          <Children>
            {aspect.aspects?.map((childAspect, idx) => (
              <AspectCard
                key={childAspect.id}
                index={idx}
                level={level + 1}
                aspect={childAspect}
                itemNumber={itemNumber + 1}
                top={idx === 0}
                lastInGroup={idx === aspect.aspects.length - 1}
                onBetweenEnter={() => hoverToggler.on()}
                onBetweenExit={() => hoverToggler.off()}
                displayTasks
              />
            ))}
            {aspect.aspects.length === 0 && (
              <PopMenu
                anchor="mouse"
                items={[
                  menuOptions.addAspect({
                    target: { id: aspect.id },
                    position: 9999,
                    callback: afterAddAspect,
                  }),
                  menuOptions.addAspect({
                    target: { id: aspect.id },
                    position: 9999,
                    type: "group",
                    callback: afterAddAspect,
                  }),
                ]}
              >
                <LaneButton
                  style={{
                    "--animation-delay": `${((itemNumber + 1 + index) * 100)}ms`,
                  }}
                />
              </PopMenu>
            )}
          </Children>
        )}
      </Wrapper>
      <PopMenu
        anchor="mouse"
        items={[
          menuOptions.addAspect({
            target: { id: aspect.parentId },
            position: 9999,
            callback: afterAddAspect,
          }),
          menuOptions.addAspect({
            target: { id: aspect.parentId },
            position: 9999,
            type: "group",
            callback: afterAddAspect,
          }),
        ]}
      >
        <Between
          onMouseEnter={onBetweenEnter}
          onMouseLeave={onBetweenExit}
          data-css-last-in-group={lastInGroup}
        />
      </PopMenu>
    </>
  )
}

export default AspectCard
