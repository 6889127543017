import styled from "styled-components"

export const Wrapper = styled.div`
  height: 100%;
  overflow: hidden;
  background: var(--panel-background-color);
  border-right: solid 1px var(--border-subdued-color);
`

export const Layout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
`

Layout.Top = styled.div`
  flex: 0 0 auto;
`

Layout.Main = styled.div`
  flex: 1 1 auto;
  overflow: hidden;
`

Layout.Bottom = styled.div`
  flex: 0 0 auto;
`
