import React, { useState } from "react"
import Loader from "components/Loader"
import AspectExplorer from "./AspectExplorer"
import { Layout, Wrapper } from "./styles"
import ProjectSelector from "./ProjectSelector"
import Filter from "./Filter"
import Options from "./Options"

function Navigation ({
  activeAspectId,
  aspects = [],
  projects = [],
  activeProjectId,
  isLoading,
}) {
  const [filterValue, setFilterValue] = useState("")

  return (
    <Wrapper>
      <Loader active={isLoading}>
        <Layout>
          <Layout.Top>
            {projects.length > 0 && (
              <ProjectSelector
                projects={projects}
                activeProjectId={activeProjectId}
              />
            )}
          </Layout.Top>
          <Layout.Main>
            {projects.length > 0 && (
              <>
                <Filter
                  value={filterValue}
                  onChange={(e) => setFilterValue(e.target.value)}
                  onCancel={(e) => setFilterValue("")}
                />
                <AspectExplorer
                  aspects={aspects}
                  activeId={activeAspectId}
                  filter={filterValue}
                  isLoading={isLoading}
                />
              </>
            )}
          </Layout.Main>
          <Layout.Bottom>
            <Options />
          </Layout.Bottom>
        </Layout>
      </Loader>
    </Wrapper>
  )
}

export default Navigation
