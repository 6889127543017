import styled from "styled-components"

export const Status = styled.div`
  height: 16px;
  padding: 0 5px;
  margin-left: 5px;
  font-size: 9px;
  font-weight: bold;
  line-height: 14px;
  text-align: center;
  background: var(--panel-subdued-background-color);
  border: solid 1px var(--border-hard-color);
  border-radius: 8px;
  color: var(--text-color);
`
