import React, { useContext } from "react"
import { Uploader } from "components/Uploader"
import { AssetList } from "components/AssetList"
import { Context as AppContext } from "AppContext"
import { Section } from "components/Section"
import { Button } from "components/modals/AspectDetail/Button"

function AttachmentsSection ({ aspect }) {
  const { actions, menuOptions } = useContext(AppContext)

  return (
    <Uploader
      onAdd={actions.attachment.curried.addToAspectVars(aspect)}
      multiple
    >
      {({ upload }) => (
        <>
          {aspect.attachments.length > 0 && (
            <Section
              headerOptions={{
                label: "Assets",
                menu: {
                  items: [
                    menuOptions.attachAsset({ upload }),
                    menuOptions.archiveAspectAttachments(aspect),
                  ],
                },
              }}
            >
              <AssetList items={aspect.attachments} />
            </Section>
          )}
          <Button onClick={upload}>Add Asset</Button>
        </>
      )}
    </Uploader>
  )
}

export default AttachmentsSection
