import React, { useContext } from "react"
import { PopMenu } from "components/PopMenu"
import { Context } from "AppContext"
import Lane, { EmptyLane } from "./Lane"
import { LaneButton } from "./Lane/LaneButton"
import { Wrapper, Scroller } from "./styles"

function Lanes ({
  aspects = [],
  rootAspect,
}) {
  const { menuOptions } = useContext(Context)

  // TODO: memoize this
  const lanes = aspects.reduce((acc, aspect, index) => {
    if (!aspect.aspects.length && aspect.type !== "group") {
      if (acc.length && acc.at(-1).type === "virtualGroup") {
        acc.at(-1).aspects.push(aspect)
        acc.at(-1).position = aspect.position
      } else {
        acc.push({
          type: "virtualGroup",
          label: "Virtual Group",
          position: aspect.position,
          level: 0,
          parentId: rootAspect.id,
          aspects: [aspect],
          id: rootAspect.id,
        })
      }
    } else {
      acc.push(aspect)
    }

    return acc
  }, [])

  return (
    <Wrapper>
      <Scroller>
        {lanes.map((aspect, index) => (
          <Lane
            key={aspect.id + (aspect.type === "virtualGroup" ? index : "")}
            aspect={aspect}
            index={index}
          />
        ))}
        <EmptyLane center>
          {lanes.length === 0 && (
            <PopMenu
              anchor="mouse"
              items={[
                menuOptions.addAspect({
                  target: rootAspect,
                  position: 0,
                }),
                menuOptions.addAspect({
                  target: rootAspect,
                  position: 0,
                  type: "group",
                }),
              ]}
            >
              <LaneButton fixed />
            </PopMenu>
          )}
        </EmptyLane>
      </Scroller>
    </Wrapper>
  )
}

export default React.memo(Lanes)
/*
    <Tooltip
      items={[{
        title: "Add Aspect or Group",
        description: "The aspect you're viewing is empty. Add an aspect to it to get started.",
      }, {
        description: "Then your aspect will have an aspect
        that you can add aspects to that can have aspects added to it. Does that make sense?",
      }]}
    >
    </Tooltip>
    */
