import styled from "styled-components"

export const Wrapper = styled.div`
  position: relative;
  height: 60px;
  padding: 0 20px;
  line-height: 60px;
  border-bottom: solid 1px var(--border-subdued-color);
`

export const Label = styled.div`
  font-size: 18px;
  font-weight: 400;
`

// TODO: implement
export const Menu = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  display: none;
  width: 100%;
`
