import styled from "styled-components"
import TransientTextField from "components/inputs/Textfield"

export const TextField = styled(TransientTextField).attrs({
  spellCheck: false,
  multiline: true,
})`
  font-size: 16px;
  line-height: 22px;

  textarea {
    padding: 10px 0;

    &:focus {
      padding-right: 10px;
      padding-left: 10px;
      margin-right: -10px;
      margin-left: -10px
    }
  }
`
