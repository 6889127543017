import styled, { keyframes } from "styled-components"
import buoy from "assets/logo.svg"

const spin = keyframes`
  0% {
    transform: rotate(0deg); 
  }
  100% {
    transform: rotate(360deg); 
  }
`

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`

export const Indicator = styled.div`
  width: 30px;
  height: 30px;
  opacity: 0;
  transition: all 200ms;
  transform: translate(-10px);
  animation-direction: forward;

  &:before {
    display: block;
    width: 100%;
    height: 100%;
    content: '';
    background: url(${buoy});
    background-size: cover;
    transform: translateZ(0);
    animation: ${spin} 1.4s infinite linear;
  }

  & {
    ${p => p.active && `
      opacity: 1;
      transform: translateX(0);
    `}
  }
`
