import styled, { css } from "styled-components"

export const Wrapper = styled.div`
  position: relative;
`

const inputCss = css`
  width: 100%;
  height: 50px;
  padding-left: 20px;
  font-size: 14px;
  color: var(--text-color);
  background: #FFFFFF;
  border: 1px solid var(--border-subdued-color);
  border-radius: 4px;
  transition: all 200ms;
  font-family: inherit;
  font-weight: inherit;
  vertical-align: top;

  &::placeholder {
    color: #C5D1F2;
    font-weight: 200;
  }

  &[data-css-error="true"] {
    border-color: #DA6565;
    background: #FEFAFA;

    &::placeholder {
      color: #F2C5C5;
    }
  }

  &:not([data-css-error="true"]) {
    &:focus {
      border-color: #658FDA;
    }

    &:placeholder {
      color: #C5D1F2;
      font-weight: lighter;
    }
  }
`

export const Input = styled.input`
  ${inputCss}

  line-height: 50px;
`

export const Textarea = styled.textarea`
  ${inputCss}

  padding: 15px 20px;
  line-height: 20px;
  resize: none;
  height: ${p => p.rows * 20}px;
`

export const Label = styled.div`
  position: absolute;
  right: 5px;
  bottom: -5px;
  height: 11px;
  line-height: 11px;
  padding: 0 5px;
  font-size: 10px;
  background: linear-gradient(180deg,
    rgba(0,0,0,0) calc(50% - 2px),
    rgba(255,255,255, 1) calc(50%),
    rgba(0,0,0,0) calc(50% + 2px)
  );
  color: var(--text-subdued-color);
  transition: all 200ms;

  ${Textarea}:focus ~ &,
  ${Input}:focus ~ & {
    color: var(--text-color);
  }
`
