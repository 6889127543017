// import React from "react"
import { useQuery } from "@apollo/client"
import { useNavigate } from "react-router-dom"
import gql from "graphql-tag"

const GET_PROJECTS_QUERY = gql`
  query GetProjects {
    aspects(parentId: null) {
      id
    }
  }
`

function DashboardPage () {
  const navigate = useNavigate()
  const { data, loading } = useQuery(GET_PROJECTS_QUERY)

  if (loading) return null

  if (data.aspects.length === 0) {
    navigate("/project/start")
  } else {
    navigate(`/project/${data.aspects[0].id}`)
  }
}

export default DashboardPage
