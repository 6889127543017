import styled, { keyframes } from "styled-components"

const showAnimation = keyframes`
  from {
    opacity: 0; 
    transform: translateX(-10px); 
  }
  to {
    opacity: 1; 
    transform: translateX(0);
  }
`

const pulse = keyframes`
  from {
    opacity: .9; 
    transform: scale(.9); 
  }
  to {
    opacity: 1; 
    transform: scale(1); 
  }
`

export const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  background: white;
  border: solid 1px var(--border-hard-color);
  border-radius: 8px 8px;
  box-shadow: 0 0 8px 0 rgba(176,201,250,0.29);

  &[data-css-padded="true"] {
    padding: 30px;
  }

  &[data-css-width] {
    width: attr(data-css-width)
  }

  &[data-css-animate] {
    animation: ${showAnimation} 300ms;
    animation-delay: 50ms;
    animation-fill-mode: both;
  }
`

export const Layout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  width: 100%;
`

Layout.Top = styled.div`
  flex: 0 0 auto;
  border-bottom: solid 1px var(--border-subdued-color);
`

Layout.Bottom = styled.div`
  flex: 1 1 auto;
  overflow: hidden;
`

export const Content = styled.div`
  padding: 30px;
`

export const Heading = styled.div`
  font-size: 18px;
  color: var(--text-color);
  text-align: center;

  &:not(:last-child) {
    margin-bottom: 30px;
  }

  & {
    ${p => p.state === "error" && `
      color: #DA6565
    `}
  }
`

export const Links = styled.div`
  height: 60px;
  line-height: 60px;
  text-align: center;

  a {
    font-size: 14px;
    color: var(--text-subdued-color);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`

export const ErrorIconWrapper = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  color: #DA6565;
  animation: ${pulse} 600ms;
  animation-fill-mode: both;
  animation-direction: alternate;
  animation-iteration-count: infinite;
`
