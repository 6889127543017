import styled from "styled-components"
import TransientTextField from "components/inputs/Textfield"

export const Wrapper = styled.div`
`

export const Item = styled.div`
  & {
    position: relative;
    font-size: 16px;
    font-weight: normal;
    background: white;
  }

  &:not(:first-child) {
    border-top: solid 1px var(--border-subdued-color);
  } 

  &:not(last-child) {
    border-radius: 6px;
  }


  ${Wrapper}[data-spacing="default"] & {
    padding: 10px 0;
  }

  
  & {
    ${p => p.isHoveredOver && `
      outline: solid 1px red;
   `}
   
    ${p => p.width && `
      width: ${p.width}px
   `}

    ${p => p.dropTarget && `
      border: solid 1px red;
      height: 4px;
   `}

    ${p => p.clone && `
      box-shadow: 0 0 4px #0004;
      margin-left: 0;
   `}

    ${p => p.isDragging && `
      background: #fafafa;
      box-shadow: inset 0 1px  6px #0003;

      > * {
         opacity: 0
      }
   `}

    ${p => p.attention && `
      &:before {
        content: "";
        border: solid 3px black;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-radius: 3px;
        display: none;
      }
   `}
  }
`

export const ItemRow = styled.div`
  display: flex;
  align-items: flex-start;
`

export const ItemColumn = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  min-height:40px;
  line-height: 20px;
  cursor: default;

  ${/* sc-prop */ ItemRow}[data-css-no-min-height="true"] & {
    min-height: 0;
  }

  &[data-css-dummy="true"] {
    visibility: hidden;
  }

  > * {
    flex-grow: 1
  }

  svg {
    vertical-align: middle;
  }

  &:not(:first-child) {
    margin-left: 5px;
  }

  &:not(:last-child) {
    margin-right: 5px;
  }

  & {
    ${p => p.flex && `
      flex-grow: 1;
   `}
  }
`

export const ItemExpandColumn = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  margin-left: -25px;
  font-size: 20px;
  color: #ccc;

  svg {
    font-size: inherit;
    vertical-align: middle
  }
`
export const TextField = styled(TransientTextField)`
  line-height: 40px;
`
