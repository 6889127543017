import React, { useMemo, useEffect, useState } from "react"
import gql from "graphql-tag"
import { Helmet } from "react-helmet"
import { useParams } from "react-router-dom"
import { useQuery } from "@apollo/client"
import { arrayToTree } from "performant-array-to-tree"
import { AppContext } from "AppContext"
import { ProjectBar } from "components/ProjectBar"
import { Navigation } from "components/Navigation"
import { AspectBoard } from "components/AspectBoard"
import { AspectDetail } from "components/modals/AspectDetail"
import { Layout } from "./styles"

const Context = React.createContext({})

export const GET_PROJECTS_QUERY = gql`
  query GetProjects {
    aspects(parentId: null) {
      id
      label
    }
  }
`

const GET_CURRENT_PROJECT_TREE_QUERY = gql`
  query GetProjects($parentId: String!) {
    aspects(id: $parentId, recursion: true) {
      id
      label
      parentId
      position
      level
      type
      dateUpdated
      state
    }
  }
`

function AppPage () {
  const {
    action,
    aspectId,
    projectId,
    viewId,
  } = useParams()
  const [isReady, setReady] = useState(false)
  const projectQuery = useQuery(GET_PROJECTS_QUERY)
  const projects = projectQuery?.data?.aspects || []

  useEffect(() => {
    setTimeout(() => {
      setReady(true)
    }, 1500)
  }, [])

  const currentProjectTreeQuery = useQuery(GET_CURRENT_PROJECT_TREE_QUERY, {
    variables: {
      parentId: projectId,
    },
  })

  let currentProjectTree = []

  if (currentProjectTreeQuery.data) {
    const filtered = currentProjectTreeQuery.data.aspects
    .filter(t => t.state !== "archived")

    const newProjectTree = (arrayToTree(
      filtered,
      {
        childrenField: "aspects",
        dataField: null,
      }
    ))

    currentProjectTree = newProjectTree
  }

  const context = useMemo(() => {}, [aspectId])

  return (
    <Context.Provider value={context}>
      <AppContext>
        <Helmet>
          <title>Swimteam</title>
        </Helmet>
        <Layout>
          <Layout.ProjectBar>
            <ProjectBar
              loading={!isReady || projectQuery.loading}
              projects={projects}
              activeProjectId={projectId}
            />
          </Layout.ProjectBar>
          <Layout.Navigation>
            <Navigation
              aspects={currentProjectTree}
              projects={projects}
              isLoading={!isReady || projectQuery.loading || currentProjectTreeQuery.loading}
              activeAspectId={aspectId || projectId}
              activeProjectId={projectId}
            />
          </Layout.Navigation>
          <Layout.Main>
            <AspectBoard
              aspectId={aspectId || projectId}
              isLoading={!isReady || projectQuery.loading || currentProjectTreeQuery.loading}
            />
          </Layout.Main>
        </Layout>
        {(action === "view" && viewId) && (
          <AspectDetail
            id={viewId}
            onBreadcrumbClick={() => null}
          />
        )}
      </AppContext>
    </Context.Provider>
  )
}

export {
  AppPage,
  Context,
}

export default AppPage
