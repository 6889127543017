import styled from "styled-components"
import TextfieldComponent from "components/inputs/Textfield"

export const Wrapper = styled.div`
  position: relative;
  width: 200px;
  overflow: auto;
`

export const Sections = styled.div`
  padding: 0 30px;
  padding-bottom: 200px;
`

export const TextField = styled(TextfieldComponent).attrs({
  multiline: true,
  spellCheck: false,
})`
  flex-grow: 1;
  font-size: 12px;
  line-height: 18px;
  resize: none;

  textarea {
    padding: 5px;
    margin: -5px;
  }
`
