import * as AbsintheSocket from "@absinthe/socket"
import { createAbsintheSocketLink } from "pluralsh-absinthe-socket-apollo-link"
import { hasSubscription } from "@jumpn/utils-graphql"
import { setContext } from "@apollo/client/link/context"
import gql from "graphql-tag"
import { HttpLink, ApolloClient, InMemoryCache, ApolloLink } from "@apollo/client"
import { Socket as PhoenixSocket } from "phoenix"

export const typeDefs = gql`
  extend type Query {
    loading: Boolean!
  }
  extend type Attachment {
    loading: Boolean!
  }
  extend type Fragment {
    loading: Boolean!
  }
`

const httpEndpoint = () => (process.env.NODE_ENV === "development"
  ? "//localhost:4000/api"
  : "//app.swimteam.app/api")

const wsEndpoint = () => (process.env.NODE_ENV === "development"
  ? "//localhost:4000/socket"
  : "//app.swimteam.app/socket")

// Create an HTTP link to the Phoenix app's HTTP endpoint URL.
const httpLink = new HttpLink({
  uri: httpEndpoint(),
})

// Create a WebSocket link to the Phoenix app's socket URL.
const socketLink = createAbsintheSocketLink(
  AbsintheSocket.create(new PhoenixSocket(wsEndpoint()))
)

// If an authentication token exists in local storage, put
// the token in the "Authorization" request header.
// Returns an object to set the context of the GraphQL request.
const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("auth-token")
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  }
})

// Create a link that "splits" requests based on GraphQL operation type.
// Queries and mutations go through the HTTP link.
// Subscriptions go through the WebSocket link.
// eslint-disable-next-line
const link = new ApolloLink.split(
  operation => hasSubscription(operation.query),
  socketLink,
  authLink.concat(httpLink)
)

// const loadingIds = makeVar([]);

const client = new ApolloClient({
  link,
  resolvers: {},
  cache: new InMemoryCache({
    typePolicies: {
      Aspect: {
        fields: {
          type: {
            read (existing, { readField }) {
              return existing
            },
          },
          meta: {
            read (_existing, { cache, readField }) {
              const tasks = readField("tasks").map(({ __ref: ref }) => cache.data.data[ref])
              // TODO: investigate why task is undefined/null when archived
              return {
                tasks: {
                  total: tasks.length,
                  completed: tasks.filter(t => t?.completed).length,
                  estimate: tasks.reduce((acc, t) => acc + (t?.estimate || 0), 0),
                },
              }
            },
          },
        },
      },
      Attachment: {
        fields: {
          primary: {
            merge: (existing, incoming, { cache, readField, toReference, variables }) => {
              if (incoming === true && existing !== true) {
                const aspectId = readField("aspectId", toReference(`Attachment:${variables.id}`))
                const currentActive =
                Object.keys(cache.data.data)
                .find(key => (
                  cache.data.data[key].primary && cache.data.data[key].aspectId === aspectId
                ))

                cache.modify({
                  id: currentActive,
                  fields: {
                    primary: () => false,
                  },
                })
              }

              return incoming
            },
          },
          primaryImage: {
            read (_, { readField }) {
              return ""
            },
          },
          loading: {
            read: (incoming, { readField }) => (
              readField("mediaPath") === null
            ),
            merge: (incoming) => false,
          },
          source: {
            read (_, { readField }) {
              return readField("mediaPath")
            },
          },
          thumbnail: {
            read (_, { readField }) {
              return readField("mediaPath")
            },
          },
        },
      },
    },
  }),
  defaultOptions: {
    watchQuery: {
      nextFetchPolicy: "network-only",
    },
  },
  typeDefs,
})

export default client
