import styled from "styled-components"

export const Wrapper = styled.div`
  position: absolute;
  top:0;
  left: 0;
  z-index: 1000;
  display: flex;
  width: 100%;
  height: 100%;
  pointer-events: ${p => p.pointerEvents};
  transition: background 400ms;
  align-items: center;

  & {
    ${p => p.darken !== false && `
      background: rgba(39, 101, 178, .20);
      background: #F0F4FD99;
      //backdrop-filter: blur(5px)
    `}
  }
`
