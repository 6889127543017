import styled from "styled-components"
import { Card as BaseCard } from "components/Card"

export const Wrapper = styled(BaseCard)` 
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text-subdued-color);
  cursor: pointer;
  border: 1px solid var(--border-hard-color);
  border-radius: 8px;
  transition: background 200ms;
  background: transparent;
  position: relative;
  height: 50px;
  transition: all 200ms;
  svg {
    font-size: 20px;
  }

  &[data-css-popup-active="true"],
  &:hover {
    background: var(--panel-subdued-background-color);
  }

  &[data-css-fixed] {
    width: 100px;
    height: 100px;
  }
`
