import React, { useState, useMemo } from "react"
import { useQuery } from "@apollo/client"
import { arrayToTree } from "performant-array-to-tree"
import { queries } from "queries"
import { Loader } from "components/Loader"
import { Context } from "./Context"
import { Heading } from "./Heading"
import { Lanes } from "./Lanes"
import {
  Wrapper,
  Layout,
  Box,
} from "./styles"

function AspectBoard ({
  aspectId,
  isLoading,
}) {
  const [aspect, setAspect] = useState({})
  const [aspectIdPendingFocus, setAspectIdPendingFocus] = useState(null)

  const { loading, previousData } =
    useQuery(queries.GET_BOARD_ASPECTS_QUERY, {
      variables: { id: aspectId },
      onCompleted: (data) => {
        const rootParentId = data.aspects.find(t => t.id === aspectId).parentId
        const filtered = data.aspects.filter(t => t.state !== "archived")

        const a = (arrayToTree(filtered, {
          childrenField: "aspects",
          dataField: null,
          rootParentIds: { [rootParentId]: true },
        }))[0]

        setAspect(a)
      },
    })

  const context = useMemo(() => ({
    aspectIdPendingFocus,
    setAspectIdPendingFocus,
  }), [aspectIdPendingFocus])

  return (
    <Context.Provider value={context}>
      <Wrapper>
        <Loader active={isLoading || (loading && !previousData)}>
          <Box />
          <Layout>
            <Layout.Top>
              <Heading breadcrumbs={aspect.breadcrumb} />
            </Layout.Top>
            <Layout.Bottom>
              <Lanes
                aspects={aspect?.aspects}
                rootAspect={aspect}
              />
            </Layout.Bottom>
          </Layout>
        </Loader>
      </Wrapper>
    </Context.Provider>
  )
}

export default AspectBoard
