import * as queries from "queries/aspect_queries"
import { useMutation } from "@apollo/client"
// import gql from "graphql-tag"

const useAspects = ({ getParams }) => {
  // aspects
  // look into this logic and see if it can be simplified
  const [addMut] = useMutation(queries.ADD_ASPECT_QUERY, {
    refetchQueries: ["GetProjects"],
    update: (cache, { data: { addAspect: newAspect } }) => {
      const { aspectId, projectId } = getParams()

      const activeId = aspectId || projectId

      const { aspects } = cache.readQuery({
        query: queries.GET_BOARD_ASPECTS_QUERY,
        variables: { id: activeId },
      })

      cache.writeQuery({
        query: queries.GET_BOARD_ASPECTS_QUERY,
        variables: {
          id: activeId,
        },
        data: { aspects: aspects.concat([newAspect]) },
      })

      cache.modify({
        id: `Aspect:${newAspect.parentId}`,
        fields: {
          aspects: (cachedTasks) => {
            cachedTasks.concat(newAspect)
          },
        },
      })
    },
  })

  const add = (params, callback) => {
    addMut(params)
      .then((result) => {
        if (callback) callback(result.data.addAspect)
      })
  }

  const addVars = (variables, callback) => add({ variables }, callback)

  const [update] = useMutation(queries.UPDATE_ASPECT_QUERY, {
    update: (cache, data) => {
      // console.log(data)
    },
  })

  const updateVars = (variables) => update({ variables })

  const move = async ({
    variables: {
      aspectId,
      id: taskId,
      newPosition,
      oldPosition,
    },
  }) => {
    const { client } = getParams()

    const aspect = client.readFragment({
      id: `Aspect:${aspectId}`,
      fragment: queries.GET_ASPECT_WITH_TASKS,
      fragmentName: "AspectWithTasks",
    })

    await client.mutate({
      fetchPolicy: "no-cache",
      mutation: queries.MOVE_TASK_QUERY,
      variables: {
        id: taskId,
        position: newPosition === aspect.tasks.length ? null : newPosition,
      },
    })

    const updatedTask = {
      ...aspect.tasks.find(t => t.id === taskId),
      position: newPosition,
    }

    const existingTasks = [...aspect.tasks]

    existingTasks.splice(oldPosition, 1)
    existingTasks.splice(newPosition, 0, updatedTask)

    const newTasks = existingTasks.map((t, i) => ({ ...t, position: i }))

    client.writeFragment({
      id: client.cache.identify(aspect),
      fragment: queries.GET_ASPECT_WITH_TASKS,
      fragmentName: "AspectWithTasks",
      data: {
        tasks: newTasks,
      } })
  }

  const moveVars = (variables) => move({ variables })

  return {
    add,
    addVars,
    update,
    updateVars,
    move,
    moveVars,
  }
}

export {
  useAspects,
}
