import React from "react"
import { EmptyHeading } from "./Heading"

import {
  Wrapper,
  Layout,
  Content,
} from "./styles"

function EmptyLane ({
  center,
  children,
}) {
  return (
    <Wrapper>
      <Layout>
        <Layout.Top>
          <EmptyHeading />
        </Layout.Top>
        <Layout.Bottom>
          <Content data-css-center={center}>
            {children}
          </Content>
        </Layout.Bottom>
      </Layout>
    </Wrapper>
  )
}

export default EmptyLane
