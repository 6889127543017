import { useState } from "react"

export const useToggle = (initialStateOrOptions = false) => {
  const [state, setState] = useState(initialStateOrOptions?.initialValue || initialStateOrOptions)
  const [props, setProps] = useState()

  const setTrue = (newProps) => {
    setState(true)
    setProps(newProps)

    if (initialStateOrOptions?.on) {
      [].concat(initialStateOrOptions.on).forEach(func => func(newProps))
    }
  }

  const setFalse = () => {
    setState(false)

    if (initialStateOrOptions?.off) {
      [].concat(initialStateOrOptions.off).forEach(func => func())
    }
  }

  const toggle = (newProps) => {
    setState(!state)
    setProps(newProps)
  }

  // return [state, setTrue, setFalse, toggle, props];
  return {
    isOn: state === true,
    isOff: state !== true,
    on: setTrue,
    off: setFalse,
    toggle,
    props,
    setProps,
  }
}

export default useToggle
