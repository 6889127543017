import React, { useState, useEffect } from "react"
import {
  Wrapper,
  Indicator,
} from "./styles"

function Loader ({
  active,
  children,
}) {
  const [showLoading, setShowLoading] = useState(active)
  const [showContent, setShowContent] = useState(!active)

  useEffect(() => {
    if (active) {
      setShowContent(false)
      setTimeout(() => {
        setShowLoading(true)
      }, 0)
    } else if (!active) {
      setTimeout(() => {
        setShowLoading(false)
      }, 500)
      setTimeout(() => {
        setShowContent(true)
      }, 501)
    }
  }, [active])

  return (
    <>
      {showLoading && (
        <Wrapper>
          <Indicator active={active} />
        </Wrapper>
      )}
      {showContent && !active && children}
    </>
  )
}

export default Loader
