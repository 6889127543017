import styled, { keyframes } from "styled-components"

const show = keyframes`
  from {
    opacity: 0;
    transform: translateY(-2px)
  }

  to {
    opacity: 1;
    transform: translateY(0)
  }
`

export const Wrapper = styled.div`
  position: absolute;
  max-width: 200px;
  font-size: 12px;
  font-weight: normal;
  color: #2B3D7F;
  cursor: default;
  background: var(--panel-background-color);
  animation: ${show} 100ms;
`

export const InnerWrapper = styled.div`
  position: relative;
  z-index: 1;
  overflow:hidden;
  box-shadow: 0 0 8px 0 rgba(176,201,250,0.29);
  border: 1px solid var(--border-hard-color);

  ${Wrapper}[data-css-error="true"] & {
    border: 1px solid #DA6565;
    color: #DA6565;
  }
`

export const ItemGroups = styled.div``

export const ItemGroup = styled.div`
  &:not(:first-child) {
    border-top: solid 1px #fff6;
  }
`

export const Items = styled.div``

export const Item = styled.div`
  padding: 10px 20px;
  line-height: 20px;

  &:active {
    transform: scale(.98)
  }


  &:not(:last-child) {
    border-bottom: 1px solid var(--border-subdued-color);
  }
`

Item.Title = styled.div`
  font-weight: 600;
`

Item.Description = styled.div`
  white-space: pre-line;
`
