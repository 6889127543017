import React, { useContext } from "react"
import * as Yup from "yup"
import gql from "graphql-tag"
import { Formik } from "formik"
import { useMutation } from "@apollo/client"
import { Context as AppContext } from "AppContext"
import { Form } from "components/Form"
import { Textfield } from "components/Form/Textfield/formik"
import { SectionHeader } from "../../styles"

const UPDATE_ACCOUNT_MUTATION = gql`
  mutation UpdateAccount($firstName: String!, $lastName: String!, $email: String!) {
    updateAccount(firstName: $firstName, lastName: $lastName, email: $email) {
      firstName
      lastName
      email
    }
  }
`

const ProfileSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, "Too Short!")
    .max(70, "Too Long!")
    .required("Required"),
  lastName: Yup.string()
    .min(2, "Too Short!")
    .max(70, "Too Long!")
    .required("Required"),
  email: Yup.string()
    .email("Invalid email")
    .required("Required"),
})

function Account ({
  onSubmit: handleSubmit,
}) {
  const { getParams } = useContext(AppContext)
  const { user } = getParams()

  const [updateAccount, { error: updateAccountError }] = useMutation(
    UPDATE_ACCOUNT_MUTATION,
    { refetchQueries: ["GetCurrentUser"] }
  )

  return (
    <>
      <SectionHeader>Profile</SectionHeader>
      <Formik
        validationSchema={ProfileSchema}
        validateOnBlur={false}
        initialValues={{
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
        }}
        onSubmit={(variables, { setStatus }) => {
          updateAccount({ variables })
               .catch((error) => {
                 setStatus(error.message)
               })
              .then(result => handleSubmit(result))
        }}
      >
        {({ errors, touched }) => (
          <Form>
            {updateAccountError && updateAccountError.message}
            <Form.Rows>
              <Form.Row>
                <Form.Col>
                  <Textfield
                    name="firstName"
                    error={touched.firstName && errors.firstName}
                    placeholder="First Name"
                    autoFocus
                  />
                </Form.Col>
                <Form.Col>
                  <Textfield
                    name="lastName"
                    error={touched.lastName && errors.lastName}
                    placeholder="Last Name"
                    autoFocus
                  />
                </Form.Col>
              </Form.Row>
              <Form.Row>
                <Form.Col>
                  <Textfield
                    name="email"
                    error={touched.email && errors.email}
                    placeholder="Email Address"
                  />
                </Form.Col>
              </Form.Row>
            </Form.Rows>
            <Form.Actions>
              <Form.Action>
                <Form.Button type="submit">Update</Form.Button>
              </Form.Action>
            </Form.Actions>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default Account
