import React from "react"
import { Helmet } from "react-helmet"
import { Wrapper, Heading } from "./styles"

function GatewayPage ({
  children,
  title = "",
}) {
  return (
    <Wrapper>
      <Helmet>
        <title>{[title, "Swimteam"].filter(Boolean).join(" | ")}</title>
      </Helmet>
      <Heading>{"Swimteam".split("").map((c, i) => <span key={i}>{c}</span>)}</Heading>
      {children}
    </Wrapper>
  )
}

export default GatewayPage
