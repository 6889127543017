import React from "react"
import {
  Input,
  Label,
  Wrapper,
  Textarea,
} from "./styles"

function Textfield ({
  autoFocus,
  error,
  label,
  multiline,
  name,
  onBlur,
  onChange,
  placeholder,
  rows,
  type,
  value,
  ...rest
}) {
  return (
    <Wrapper data-css-error={!!error}>
      {multiline !== true && (
        <Input
          name={name}
          placeholder={placeholder}
          value={value}
          data-css-error={!!error}
          onChange={onChange}
          onBlur={onBlur}
          autoFocus={autoFocus}
          type={type}
        />
      )}
      {multiline === true && (
        <Textarea
          name={name}
          placeholder={placeholder}
          data-css-error={!!error}
          onChange={onChange}
          onBlur={onBlur}
          autoFocus={autoFocus}
          rows={rows}
          value={value}
        />
      )}
      {value?.trim() && (
        <Label>{label || placeholder}</Label>
      )}
    </Wrapper>
  )
}

export default Textfield
