/* eslint-disable react/no-this-in-sfc */
import React, {
  useLayoutEffect,
  useState,
} from "react"
import ReactDOM from "react-dom"
import kebabCase from "lodash/kebabCase"

function Uploader ({
  children,
  multiple,
  onAdd,
  ...restProps
}) {
  const hiddenFileInput = React.useRef(null)
  const [portalRef, setPortalRef] = useState()

  useLayoutEffect(() => {
    const el = document.createElement("span")
    document.body.appendChild(el)
    setPortalRef(el)

    return () => {
      document.body.removeChild(el)
    }
  }, [])

  const triggerUploader = () => {
    hiddenFileInput.current.click()
  }

  const uploadFile = (index, file) => {
    const formData = new window.FormData()
    const xhr = new window.XMLHttpRequest()
    const cloudId = `${new Date().valueOf()}-${kebabCase(file.name)}`

    const onComplete = onAdd({
      name: file.name,
      completed: false,
      cloudId,
    })

    formData.append("file", file)
    formData.append("upload_preset", "lnp4ivyv")
    formData.append("public_id", cloudId)

    xhr.responseType = "json"

    /* eslint-disable-next-line */
    xhr.onreadystatechange = function () {
      if (this.readyState === 4 && this.status === 200) {
        onComplete({
          cloudId,
          name: file.name,
          completed: true,
          url: this.response.url,
        })
      }
    }

    xhr.open("post", "https://api.cloudinary.com/v1_1/do8lse6xi/image/upload")
    xhr.send(formData)
  }

  const handleChange = (event) => {
    for (let i = 0; i < event.target.files.length; i++) {
      uploadFile(i, event.target.files[i])
    }
  }

  if (!portalRef) return null

  return (
    <>
      {ReactDOM.createPortal(
        <input
          ref={hiddenFileInput}
          type="file"
          style={{ display: "none" }}
          multiple={multiple}
          onChange={handleChange}
        />, portalRef
      )}
      {children({ upload: triggerUploader, ...restProps })}
    </>
  )
}

export default Uploader
