import React, { useContext } from "react"
import MoreHorizIcon from "@material-ui/icons/MoreHoriz"
import AddIcon from "@material-ui/icons/Add"
import { Loader } from "components/Loader"
import { Context } from "AppContext"
import { PopMenu } from "components/PopMenu"
import { useToggle } from "hooks/useToggle"
import { Settings } from "components/modals/Settings"
import { AddProject } from "components/modals/AddProject"
import { Item } from "./Item"
import {
  Wrapper,
  Layout,
  Logo,
  Items,
  Button,
} from "./styles"

function ContextBar ({
  projects = [],
  loading,
  activeProjectId,
}) {
  const { getParams, menuOptions, navigation } = useContext(Context)
  const { navigate } = getParams()
  const settingsToggler = useToggle(false)
  const addToggler = useToggle(false)

  return (
    <Wrapper>
      <Loader active={loading}>
        <Layout>
          <Layout.Top>
            <Logo onClick={() => navigate("/project/dashboard")}>
              <img src={require("assets/logo.svg").default} alt="Logo" />
            </Logo>
          </Layout.Top>
          <Layout.Menu>
            <PopMenu
              items={[
                menuOptions.settings({ onClick: () => settingsToggler.on() }),
                menuOptions.logout(),
              ]}
            >
              <Button>
                <MoreHorizIcon />
              </Button>
            </PopMenu>
          </Layout.Menu>
          <Layout.Middle>
            <Items>
              {projects.map((project, index) => (
                <Item
                  key={project.id}
                  project={project}
                  active={activeProjectId === project.id}
                  index={index}
                  onClick={() => navigation.projectView(project)}
                />
              ))}
            </Items>
          </Layout.Middle>
          <Layout.Bottom>
            <Button onClick={addToggler.on}>
              <AddIcon />
            </Button>
          </Layout.Bottom>
        </Layout>
      </Loader>
      {settingsToggler.isOn && (
        <Settings onClose={settingsToggler.off} />
      )}
      {addToggler.isOn && (
        <AddProject onClose={addToggler.off} />
      )}
    </Wrapper>
  )
}

export default ContextBar
