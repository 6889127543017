import React, { useContext } from "react"
import { Uploader } from "components/Uploader"
import { Context as AppContext } from "AppContext"
import { Image as ImageComponent } from "components/Image"
import BackupOutlinedIcon from "@material-ui/icons/BackupOutlined"
import { Wrapper } from "./styles"

function Image ({
  aspect,
}) {
  const primaryImage = aspect.attachments.find(a => a.primary)
  const { actions, menuOptions } = useContext(AppContext)

  return (
    <Uploader onAdd={
      actions.attachment.curried.addVars({
        aspectId: aspect.id,
        primary: true,
      })
    }
    >
      {({ upload }) => (
        <ImageComponent
          size="auto"
          aspect="square"
          loading={!!primaryImage?.loading}
          source={primaryImage?.mediaPath}
          primary={!!primaryImage}
          menu={{
            items: primaryImage
              ? ([
                menuOptions.clearAttachment({ attachmentId: primaryImage.id }),
                menuOptions.archiveAttachment({ attachmentId: primaryImage.id }),
              ])
              : [],
          }}
        >
          <Wrapper onClick={() => upload()}>
            <BackupOutlinedIcon />
          </Wrapper>
        </ImageComponent>
      )}
    </Uploader>
  )
}

export default Image
