/* eslint-disable */
import React, { useContext } from "react"
import { Context as AppContext } from "AppContext"
import { Image } from "./NewImage"
import { Section } from "./Section"
import { Wrapper, Sections, TextField } from "./styles"

function Side ({ aspect }) {
  const { actions, menuOptions } = useContext(AppContext)

  return (
    <Wrapper>
      <Sections>
        <Section>
          <Image aspect={aspect} />
        </Section>
        <Section
          heading="Aim"
          options={[menuOptions.clearAspectField({
            id: aspect.id,
            fieldName: 'aim'
          })]}
        >
          <TextField
            value={aspect.aim}
            placeholder="type the aim of this aspect"
            onSubmit={(aim) => actions.aspect.updateVars({ 
              id: aspect.id,
              aim 
            })}
          />
        </Section>
        <Section
          heading="Purpose"
          options={[menuOptions.clearAspectField({
            id: aspect.id,
            fieldName: 'purpose'
          })]}
        >
          <TextField
            value={aspect.purpose}
            placeholder="type the purpose of this aspect"
            onSubmit={(purpose) => actions.aspect.updateVars({ 
              id: aspect.id,
              purpose 
            })}
          />
        </Section>
        <Section
          heading="Avoid"
          options={[menuOptions.clearAspectField({
            id: aspect.id,
            fieldName: 'avoid'
          })]}
        >
          <TextField
            value={aspect.avoid}
            placeholder="type what you want to avoid"
            onSubmit={(avoid) => actions.aspect.updateVars({ 
              id: aspect.id,
              avoid })}
          />
        </Section>
      </Sections>
    </Wrapper>
  )
}

export default Side
