import React, { forwardRef } from "react"
import AddIcon from "@material-ui/icons/Add"
import { Wrapper } from "./styles"

function LaneButton ({
  className,
  fixed,
  onClick,
  // REFACTOR: spreading hear because  when wrapped with popup menu,
  // data attributes are passed down and kind of messy
  ...rest
}, ref) {
  return (
    <Wrapper
      onClick={onClick}
      ref={ref}
      className={className}
      data-css-fixed={fixed}
      {...rest}
    >
      <AddIcon />
    </Wrapper>
  )
}

export default forwardRef(LaneButton)
