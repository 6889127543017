import styled from "styled-components"
import Textfield from "components/inputs/Textfield"

export const Wrapper = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  min-height: 40px;
`

export const TextField = styled(Textfield)`
  font-size: 24px;
  line-height: 40px;
  `
