import styled from "styled-components"

export const Wrapper = styled.div`
  &:not(:last-child) {
    border-bottom: solid 1px var(--border-subdued-color);
  }
`

export const Layout = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
`

Layout.Left = styled.div`
  display: flex;
  flex: 1 0 auto;
  align-items: center;
`

Layout.Right = styled.div`
  display: grid;
  flex: 0 0 auto;
  grid-column: auto;
  grid-auto-columns: auto;
  grid-auto-flow: column;
  grid-column-gap: 10px;
`

export const Label = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  height: 30px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  cursor: default;

  svg {
    margin-right: 5px;
    font-size: inherit;
  }

  ${Wrapper}[data-css-secondary="true"] & {
    font-size: 12px;
  }
`
