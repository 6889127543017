import React from "react"
import useToggle from "hooks/useToggle"
import { SectionHeader } from "components/SectionHeader"
import { Wrapper, Content } from "./styles"

function Section ({
  children,
  collapsable,
  headerOptions,
}) {
  const toggler = useToggle(true)

  return (
    <Wrapper>
      <SectionHeader
        onToggle={collapsable ? () => toggler.toggle() : undefined}
        {...headerOptions}
      />
      {toggler.isOn && (
        <Content>
          {children}
        </Content>
      )}
    </Wrapper>
  )
}

export default Section
