import gql, { enableExperimentalFragmentVariables } from "graphql-tag"

enableExperimentalFragmentVariables()

export const ATTACHMENT_FRAGMENT = gql`
  fragment AttachmentFields on Attachment {
    id
    label
    description
    mediaPath
    taskId
    aspectId
    source
    thumbnail
    completed
    state
    primary
    loading @client

    __typename
  }
`

export const TASK_FIELDS = gql`
  fragment TaskFields on Task {
    id
    description
    estimate
    state
    completed
    position
    aspectId
    attachments {
      ...AttachmentFields
    }
    __typename
  }

  ${ATTACHMENT_FRAGMENT}
`

export const ASPECT_FIELDS = gql`
  fragment AspectFields on Aspect {
    id
    label
    dateUpdated
    dateCreated
    estimate
    importance
    urgency
    parentId
    imageUrl
    description
    aim
    path
    level
    position
    purpose
    avoid
    state
    type
    breadcrumb {
      id
      label
    }
  }
`

export const TASK_WITH_ATTACHMENTS = gql`
  fragment TaskFieldsWithAttachments on Task {
    id
    attachments {
      ...AttachmentFields
    }
    __typename
  }

  ${ATTACHMENT_FRAGMENT}
`

export const ADD_ATTACHMENT_QUERY = gql`
  mutation AddAttachment(
    $label: String!
    $description: String
    $mediaPath: String!
    $taskId: String
    $aspectId: String
    $primary: Boolean
  ) {
    addAttachment (
      label: $label
      description: $description
      mediaPath: $mediaPath
      taskId: $taskId
      aspectId: $aspectId
      primary: $primary
    ) {
      ...AttachmentFields
    }
  }

  ${ATTACHMENT_FRAGMENT}
`

export const UPDATE_ATTACHMENT_QUERY = gql`
  mutation UpdateAttachment(
    $id: String
    $aspectId: String
    $taskId: String
    $label: String
    $description: String
    $primary: Boolean
    $completed: Boolean
    $state: String
  ) {
    updateAttachment (
      id: $id
      aspectId: $aspectId
      taskId: $taskId
      label: $label
      description: $description
      completed: $completed
      primary: $primary
      state: $state
    ) {
      ...AttachmentFields
    }
  }

  ${ATTACHMENT_FRAGMENT}
`

export const ADD_ASPECT_QUERY = gql`
  mutation AddAspect(
    $position: Float
    $parentId: String
    $label: String
    $type: String
  ) {
    addAspect(
      position: $position
      parentId: $parentId
      label: $label
      type: $type
    ) {
      ...AspectFields
    }
  }

  ${ASPECT_FIELDS}
`

export const ADD_TASK_QUERY = gql`
  mutation AddTask(
    $aspectId: String!,
    $description: String
  ) {
    addTask(
      aspectId: $aspectId
      description: $description
    ) {
      ...TaskFields
    }
  }

  ${TASK_FIELDS}
`

export const DELETE_ATTACHMENT_QUERY = gql`
  mutation DeleteAttachment(
    $attachmentId: String!,
  ) {
    deleteAttachment(attachmentId: $attachmentId) {
      ...AttachmentFields
    }
  }

  ${ATTACHMENT_FRAGMENT}
`

export const MOVE_TASK_QUERY = gql`
  mutation UpdateTask(
    $id: String!,
    $position: Float,
  ) {
  updateTask(
    id: $id
    position: $position
  ) {
    id
    position
  }
 }

 ${TASK_FIELDS}
`

export const UPDATE_TASK_QUERY = gql`
  mutation UpdateTask(
    $id: String,
    $aspect_id: String,
    $completed: Boolean,
    $estimate: Int,
    $state: String,
    $status: String,
    $position: Float,
    $description: String,
    $includeAttachments: Boolean = false
  ) {
    updateTask(
      id: $id
      aspect_id: $aspect_id
      completed: $completed
      estimate: $estimate
      description: $description
      state: $state
      status: $status
      position: $position
    ) {
      ...TaskFields
      attachments @include (if: $includeAttachments){
        ...AttachmentFields
      }
      __typename
    }
  }

  ${TASK_FIELDS}
`

export const UPDATE_ASPECT_QUERY = gql`
  mutation UpdateAspect(
    $id: String!
    $description: String
    $label: String
    $aim: String
    $purpose: String
    $avoid: String
    $state: String
  ) {
    updateAspect(
      id: $id
      label: $label
      aim: $aim
      description: $description
      avoid: $avoid
      purpose: $purpose
      state: $state,
    ) {
      ...AspectFields
    }
  }

  ${ASPECT_FIELDS}
`

export const GET_ASPECT_QUERY = gql`
  query GetAspect($id: String!) {
    aspect(recursion: false, id: $id) {
      id
      label
      tasks {
        ...TaskFields
      }
      dateUpdated
      dateCreated
      estimate
      importance
      urgency
      attachments {
        ...AttachmentFields
      }
      imageUrl
      description
      aim
      purpose
      avoid
      type
      meta @client
      breadcrumb {
        id
        label
      }
      state
    }
  }
  ${ATTACHMENT_FRAGMENT}
  ${TASK_FIELDS}
`

export const GET_THING_QUERY = gql`
  query GetAspects($id: String!) {
    aspects(recursion: true, id: $id) {
      id
      label
      tasks {
        ...TaskFields
      }
      dateUpdated
      dateCreated
      estimate
      importance
      urgency
      attachments {
        ...AttachmentFields
      }
      imageUrl
      description
      aim
      purpose
      avoid
      state
      type
      meta @client
      parentId
      breadcrumb {
        id
        label
      }
    }
  }
  ${ATTACHMENT_FRAGMENT}
  ${TASK_FIELDS}
`

export const GET_ASPECT_WITH_TASKS = gql`
  fragment AspectWithTasks on Aspect {
    id
    __typename
    attachments {
      ...AttachmentFields
    }
    tasks {
      ...TaskFields
    }
  }
  ${ATTACHMENT_FRAGMENT}
  ${TASK_FIELDS}
`

export const GET_TASK_ATTACHMENTS = gql`
  fragment Task on Task {
    id
    __typename
    attachments {
      ...AttachmentFields
    }
  }

  ${ATTACHMENT_FRAGMENT}
`

export const WRITE_ASPECT_TASKS = gql`
  fragment WriteAspect on Aspect {
    id
    __typename
    tasks {
      ...TaskFields
    }
  }
`

export const GET_BOARD_ASPECTS_QUERY = gql`
  query GetAspects($id: String!) {
    aspects(recursion: true, id: $id) {
      id
      label
      type
      imageUrl
      path
      level
      parentId
      position
      description
      state
      tasks {
        id
        description
        completed
      }
      breadcrumb {
        id
        label
      }
    }
  }
`
