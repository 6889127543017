// this should be an element
import React, { useImperativeHandle, useState, useEffect, useRef, forwardRef } from "react"
import { useDoubleClick } from "@zattoo/use-double-click"
import useToggle from "hooks/useToggle"
import TextareaAutosize from "react-textarea-autosize"
import { Wrapper } from "./styles"

const TransientTextField = forwardRef(({
  autofocus,
  blurOnConfirm = true,
  className,
  clearOnConfirm = false,
  doubleClickToEdit,
  editable = true,
  multiline,
  onBlur,
  onCancel,
  onClick,
  onFocus,
  onSubmit,
  strike,
  value: valueProp,
  ...restProps
}, ref) => {
  const editingToggler = useToggle()
  const [value, setValue] = useState(valueProp || "")
  const [dirty, setDirty] = useState(false)
  const [refresh, setRefresh] = useState(1)
  const inputRef = useRef()

  useImperativeHandle(ref, () => ({
    focus: () => {
      inputRef.current.focus()
    },
    setRange: () => {
      setRange()
    },
  }))

  const setRange = () => {
    inputRef.current.setSelectionRange(0, inputRef.current.value.length)
  }

  const handleDoubleClick = () => {
    editingToggler.on()
    ref.current.focus()
    setRange()
  }

  const hybridClick = useDoubleClick(handleDoubleClick, onClick)

  useEffect(() => {
    if (autofocus && inputRef.current) {
      inputRef.current.focus()
    }
  }, [inputRef.current])

  useEffect(() => {
    setValue(valueProp || "")
  }, [valueProp])

  useEffect(() => {
    setTimeout(() => setRefresh(2), 1)
  }, [])

  const onKeyPress = (e) => {
    if (!e.shiftKey && e.key === "Enter") {
      const result = onSubmit(e.target.value)

      setDirty(false)

      if (result !== false) {
        if (blurOnConfirm) {
          inputRef.current.blur()
        }

        if (clearOnConfirm) {
          setValue("")
        }
      }
      e.preventDefault()
      e.stopPropagation()
    }
  }

  const onChange = (e) => {
    setDirty(true)
    setValue(e.target.value)
  }

  const handleBlur = () => {
    editingToggler.off()

    if (dirty) {
      setValue(valueProp)
      setDirty(false)
      if (onCancel) onCancel()
    }

    if (!dirty) {
      if (onBlur) onBlur()
    }
  }

  const handleClick = (e) => {
    if (editingToggler.isOff && doubleClickToEdit) {
      hybridClick(e)
    } else if (editingToggler.isOff && onClick) {
      onClick(e)
    }
  }

  const handleMouseDown = (e) => {
    if (doubleClickToEdit && editingToggler.isOff) {
      document.activeElement.blur()
      e.preventDefault()
      return false
    }
    return true
  }

  const handleFocus = (e) => {
    editingToggler.on()

    if (onFocus) {
      onFocus(e)
    }
  }

  if (editable === false) {
    return (
      <Wrapper>
        <div className={className}>
          {value}
        </div>
      </Wrapper>
    )
  }

  if (multiline) {
    return (
      <Wrapper strike={strike} className={className}>
        <TextareaAutosize
          ref={inputRef}
          value={value}
          refresh={refresh}
          spellCheck="false"
          onBlur={handleBlur}
          onKeyPress={onKeyPress}
          onChange={onChange}
          onClick={handleClick}
          onMouseDown={handleMouseDown}
          onFocus={handleFocus}
          {...restProps}
        />
      </Wrapper>
    )
  }
  return (
    <Wrapper
      strike={strike}
      className={className}
    >
      <input
        ref={inputRef}
        type="text"
        value={value}
        onBlur={handleBlur}
        onKeyPress={onKeyPress}
        onChange={onChange}
        onMouseDown={handleMouseDown}
        onClick={handleClick}
        onFocus={handleFocus}
        {...restProps}
      />
    </Wrapper>
  )
})

export default TransientTextField
