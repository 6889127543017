import React, { useContext } from "react"
import { Context as AppContext } from "AppContext"
import {
  Wrapper,
  TextField,
} from "./styles"

function Header ({ aspect }) {
  const { actions } = useContext(AppContext)

  return (
    <Wrapper>
      <TextField
        value={aspect.label}
        onSubmit={(label) => {
          actions.aspect.updateVars({
            id: aspect.id,
            label: label || aspect.label,
          })
        }}
      />
    </Wrapper>
  )
}

export default Header
