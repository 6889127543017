/* eslint-disable react/jsx-props-no-spreading */
import React from "react"
import Tooltip from "components/Tooltip"
import {
  Wrapper,
  IconWrapper,
  Label,
} from "./styles"

function IconItem ({
  Icon,
  children,
  label,
  onClick,
  tooltip,
}) {
  return (
    <Tooltip
      anchor="bottomLeft"
      offsetY={10}
      {...tooltip}
    >
      <Wrapper onClick={onClick}>
        {Icon && (
          <IconWrapper>
            <Icon />
          </IconWrapper>
        )}
        {(label || children) && (
          <Label>
            {label || children}
          </Label>
        )}
      </Wrapper>
    </Tooltip>
  )
}

export default IconItem
