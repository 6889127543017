import React from "react"
import {
  Wrapper,
  Label,
  Checkmark,
  ChoiceInput,
} from "./styles"

const checkSound = require("./check.mp3")

const players = new Array(3).fill(null).map(
  () => new window.Audio(checkSound)
)

function Choice ({
  checked,
  children,
  deleted,
  label,
  onClick,
  style,
  type,
}) {
  const handleClick = () => {
    const freePlayer = players.find(p => p.paused) || players[0]
    freePlayer.play()
    onClick()
  }

  return (
    <Wrapper
      style={style}
      onClick={handleClick}
    >
      <ChoiceInput
        checked={checked}
        type={type}
      >
        <Checkmark />
      </ChoiceInput>
      {(children || label) && (
        <Label deleted={deleted}>
          {children || label}
        </Label>
      )}
    </Wrapper>
  )
}

export { Choice }

export default Choice
