import styled from "styled-components"

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
`

export const Heading = styled.h3`
  margin-bottom: 25px;
  font-size: 32px;
  font-weight: 800;
  line-height: 40px;
  color: var(--text-color);
  text-align: center;
  text-transform: uppercase;

  @keyframes wave-text{
    00%{
      opacity: 0;
      transform: translateY(0em);
    }
    
    60%{
      opacity: 1;
      transform: translateY(-0.3em)
    }

    100%{
      opacity: 1;
      transform: translateY(0em)
    }
  }

  strong {
    display: none;
    font-size: 14px;
    color: ${p => p.theme.fontColorOnBackgroundAlt};
    vertical-align: super;
  }

  span {
    display: inline-block;
    opacity: 0;
    animation: wave-text 1s ease-in-out 1;
    animation-fill-mode: forwards;

    &:nth-of-type(1) {
      animation-delay: 0.0s; 
    }
    &:nth-of-type(2){
      animation-delay: 0.1s; 
    }
    &:nth-of-type(3){
      animation-delay: 0.2s; 
    }
    &:nth-of-type(4){
      animation-delay: 0.3s; 
    }
    &:nth-of-type(5) {
      animation-delay: 0.4s; 
    }
    &:nth-of-type(6){
      animation-delay: 0.5s; 
    }
    &:nth-of-type(7){
      animation-delay: 0.6s; 
    }
    &:nth-of-type(8) {
      animation-delay: 0.7s; 
    }
  }
`
