import React, { useContext } from "react"
import AddAlarmIcon from "@material-ui/icons/AddAlarm"
import ScheduleIcon from "@material-ui/icons/Schedule"
import Add from "@material-ui/icons/Add"
import { Context as AppContext } from "AppContext"
import { Uploader } from "components/Uploader"
import { Choice } from "components/inputs/Choice"
import { duration } from "lib/helpers"
import { MenuButton } from "components/MenuButton"
import { List, SortableItems, Item, TextField } from "components/List"
import { AddButton } from "./styles"
import { AssetList } from "./AssetList"

export function TaskList ({
  items = [],
  onSort,
  aspectId,
}) {
  const { actions, menuOptions } = useContext(AppContext)

  return (
    <List>
      <SortableItems
        dndType="TASK"
        onSort={onSort}
      >
        {items.filter(i => i.state !== "archived").map((task) => (
          <Uploader
            id={task.id}
            key={task.id}
            multiple
            onAdd={actions.attachment.curried.addToTask(task)}
          >
            {({ upload }) => (
              <Item
                id={task.id}
                key={task.id}
                position={task.position}
                attention
              >
                <Item.Row>
                  <Item.Column>
                    <Choice
                      checked={task.completed}
                      onClick={() => {
                        actions.task.updateVars({
                          id: task.id,
                          completed: !task.completed,
                        })
                      }}
                    />
                  </Item.Column>
                  <Item.Column flex>
                    <TextField
                      value={task.description}
                      strike={task.completed}
                      onSubmit={(description) => {
                        actions.task.updateVars({
                          id: task.id,
                          description,
                        })
                      }}
                    />
                  </Item.Column>
                  <Item.Column>
                    <MenuButton
                      Icon={task.estimate ? null : AddAlarmIcon}
                      containerSC={Item}
                      label={task.estimate ? duration(task.estimate) : ""}
                      items={[15, 30, 60, 120].map((minutes) => ({
                        label: duration(60 * minutes),
                        Icon: ScheduleIcon,
                        onClick: () => {
                          actions.task.updateVars({
                            id: task.id,
                            estimate: 60 * minutes,
                          })
                        },
                      }))}
                    />
                  </Item.Column>
                  <Item.Column>
                    <MenuButton
                      containerSC={Item}
                      items={[
                        menuOptions.addTaskAttachment({ upload }),
                        menuOptions.archiveTask(task),
                      ]}
                    />
                  </Item.Column>
                </Item.Row>
                {task.attachments.filter(a => a.state !== "archived").length > 0 && (
                  <Item.Row>
                    <Item.Column data-css-dummy>
                      <Choice />
                    </Item.Column>
                    <Item.Column flex>
                      <AssetList
                        taskId={task.id}
                        items={task.attachments.filter(a => a.state !== "archived")}
                      />
                    </Item.Column>
                  </Item.Row>
                )}
              </Item>
            )}
          </Uploader>
        ))}
      </SortableItems>
      <Item>
        <Item.Row>
          <Item.Column>
            <AddButton>
              <Add />
            </AddButton>
          </Item.Column>
          <Item.Column flex>
            <TextField
              placeholder="start typing to add a new task"
              clearOnConfirm
              onSubmit={(description) => {
                actions.task.addVars({
                  aspectId,
                  description,
                })
              }}
            />
          </Item.Column>
        </Item.Row>
      </Item>
    </List>
  )
}

export default TaskList
