import React from "react"
import { Field } from "formik"
import Basetextfield from ".."

function Textfield (props) {
  return (
    <Field {...props} as={Basetextfield} />
  )
}

export default Textfield
