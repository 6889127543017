import React from "react"
import gql from "graphql-tag"
import * as Yup from "yup"
import { useMutation } from "@apollo/client"
import { useNavigate } from "react-router-dom"
import { Formik } from "formik"
import { Form } from "components/Form"
import { Card } from "components/Card"
import { Textfield } from "components/Form/Textfield/formik"
import { GatewayPage } from "components/GatewayPage"

const CREATE_PROJECT_MUTATION = gql`
    mutation AddAspect(
      $label: String!,
      $description: String,
  ) {
    addAspect(
      label: $label
      description: $description
    ) {
      id
    }
  }
`

const schema = Yup.object().shape({
  label: Yup.string()
  .min(2, "Too Short!")
  .max(70, "Too Long!")
  .required("Required"),
  description: Yup.string()
  .min(2, "Too Short!")
  .max(600, "Too Long!"),
})

function StartPage () {
  const navigate = useNavigate()

  const [createProject] = useMutation(CREATE_PROJECT_MUTATION, {
    onCompleted: () => {
      navigate("/project/dashboard")
    },
  })

  return (
    <GatewayPage title="Create Project">
      <Formik
        validationSchema={schema}
        initialValues={{
          label: "",
          description: "",
        }}
        validateOnBlur={false}
        onSubmit={(variables, { setStatus }) => {
          createProject({ variables }).catch((error) => {
            setStatus(error.message)
          })
        }}
      >
        {({ errors, status, touched }) => (
          <Card padded animate style={{ width: 360 }}>
            <Card.Heading state={status && "error"}>
              {status || "Create your first project"}
            </Card.Heading>
            <Form>
              <Form.Rows>
                <Form.Row>
                  <Form.Col>
                    <Textfield
                      name="label"
                      error={touched.label && errors.label}
                      placeholder="Project Name"
                      autoFocus
                    />
                  </Form.Col>
                </Form.Row>
                <Form.Row>
                  <Form.Col>
                    <Textfield
                      name="description"
                      error={touched.description && errors.description}
                      placeholder="Project Description"
                      rows={12}
                      multiline
                    />
                  </Form.Col>
                </Form.Row>
              </Form.Rows>
              <Form.Actions>
                <Form.Action>
                  <Form.Button type="submit">Create Project</Form.Button>
                </Form.Action>
              </Form.Actions>
            </Form>
          </Card>
        )}
      </Formik>
    </GatewayPage>
  )
}

export default StartPage
