import React, { useContext } from "react"
import { TaskList } from "components/TaskList"
import { Context as AppContext } from "AppContext"
// import VisibilityIcon from "@material-ui/icons/Visibility"
import { Section } from "components/Section"

function PlanSection ({ aspect }) {
  const { menuOptions } = useContext(AppContext)

  return (
    <Section
      headerOptions={{
        label: "Plan",
        count: aspect.tasks?.length,
        menu: {
          items: [menuOptions.archiveAspectAttachments(aspect)],
        },
        /*
        actions: [{
          Icon: VisibilityIcon,
          onClick: () => {
            console.log(1)
          },
          tooltip: {
            items: [{
              title: "Show Archived Tasks",
              description: [
                "Show tasks that you've marked as completed.",
              ],
            }],
          },
        }],
        */
      }}
    >
      <TaskList
        aspectId={aspect.id}
        items={aspect.tasks}
      />
    </Section>
  )
}

export default PlanSection
