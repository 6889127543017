import styled from "styled-components"

export const Wrapper = styled.div`
  height: 40px;
  font-size: 14px;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
  background: var(--panel-subdued-background-color);
  border: 1px solid var(--border-subdued-color);
  border-radius: 4px;

  &:hover {
    background: var(--panel-hover-color);
  }
`
