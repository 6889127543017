import React from "react"
import ArrowRightIcon from "@material-ui/icons/ArrowRight"
import ArrowDropDown from "@material-ui/icons/ArrowDropDown"
import MenuButton from "components/MenuButton"
import Status from "components/Status"
import {
  Wrapper,
  Label,
  Layout,
} from "./styles"

function Header ({
  children,
  count,
  label,
  menuOptions,
  menu,
  onToggle: handleToggle,
  toggled,
  secondary,
  actions = [],
  className,
}) {
  return (
    <Wrapper
      className={className}
      data-css-secondary={secondary}
    >
      <Layout>
        <Layout.Left>
          <Label onClick={handleToggle}>
            {handleToggle && (
              !toggled ? <ArrowRightIcon /> : <ArrowDropDown />
            )}
            {label || children}
          </Label>
          {!!count && (<Status>{" "} {count} {" "}</Status>)}
        </Layout.Left>
        <Layout.Right>
          {actions.map(action => (
            <MenuButton
              Icon={action.Icon}
              tooltip={action.tooltip}
              onClick={action.onClick}
            />
          ))}
          {(menuOptions || menu) && (
            <MenuButton
              {...menuOptions}
              {...menu}
            />
          )}
        </Layout.Right>
      </Layout>
    </Wrapper>
  )
}

export default Header
