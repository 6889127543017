// TODO: improve error messaging
import React from "react"
import gql from "graphql-tag"
import * as Yup from "yup"
import { useMutation } from "@apollo/client"
import { Formik } from "formik"
import { Link, useNavigate } from "react-router-dom"
import { Form } from "components/Form"
import { Textfield } from "components/Form/Textfield/formik"
import { Card, CardLinks } from "components/Card"
import { GatewayPage } from "components/GatewayPage"

const SIGNIN_MUTATION = gql`
  mutation SignIn($email: String!, $password: String!) {
    signin(email: $email, password: $password) {
      token
      user {
        email
      }
    }
  }
`

const signinSchema = Yup.object().shape({
  password: Yup.string()
    .min(2, "Too Short!")
    .max(70, "Too Long!")
    .required("Required"),
  email: Yup.string()
    .email("Invalid email")
    .required("Required"),
})

function LoginPage () {
  const navigate = useNavigate()

  const [signin] = useMutation(SIGNIN_MUTATION, {
    onCompleted: (data) => {
      localStorage.setItem("auth-token", data.signin.token)
      navigate("/project/dashboard")
    },
  })

  return (
    <GatewayPage title="Login">
      <Formik
        validationSchema={signinSchema}
        initialValues={{
          email: "",
          password: "",
        }}
        onSubmit={(variables, { setStatus }) => {
          signin({ variables }).catch((error) => {
            setStatus({ error })
          })
        }}
      >
        {({ errors, status, touched }) => (
          <>
            <Card style={{ width: 360 }} padded animate>
              <Form>
                <Card.Heading state={status?.error && "error"}>
                  {status?.error ? status.error.message : "Sign into your account"}
                </Card.Heading>
                <Form.Rows>
                  <Form.Row>
                    <Form.Col>
                      <Textfield
                        name="email"
                        error={touched.email && errors.email}
                        placeholder="Email"
                        autoFocus
                      />
                    </Form.Col>
                  </Form.Row>
                  <Form.Row>
                    <Form.Col>
                      <Textfield
                        type="password"
                        name="password"
                        error={touched.password && errors.password}
                        placeholder="Password"
                      />
                    </Form.Col>
                  </Form.Row>
                </Form.Rows>
                <Form.Actions>
                  <Form.Action>
                    <Form.Button type="submit">Sign In </Form.Button>
                  </Form.Action>
                </Form.Actions>
              </Form>
            </Card>
            <CardLinks>
              <Link to="/auth/signup">Need an account?</Link>
            </CardLinks>
          </>
        )}
      </Formik>
    </GatewayPage>
  )
}

export default LoginPage
