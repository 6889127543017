import React from "react"
import Add from "@material-ui/icons/Add"

import {
  Wrapper,
  Layout,
  TextField,
} from "./styles"

function NewItem ({
  onTaskAdd,
  onTaskEditEnd,
  onTaskEditStart,
  task,
}) {
  return (
    <Wrapper>
      <Layout>
        <Layout.Left>
          <Add />
        </Layout.Left>
        <Layout.Main>
          <TextField
            autofocus
            onCancel={() => onTaskEditEnd(task)}
            onBlur={() => onTaskEditEnd(task)}
            onFocus={() => onTaskEditStart(task)}
            onSubmit={(description) => onTaskAdd({ description })}
            placeholder="type to add a task"
            multiline
          />
        </Layout.Main>
        <Layout.Right />
      </Layout>
    </Wrapper>
  )
}

export default NewItem
