import styled from "styled-components"

export const Wrapper = styled.div`
  display: none;
  border-top: solid 1px var(--border-subdued-color);
`

export const Layout = styled.div`
`

Layout.Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;

  svg {
    font-size: 20px;
  }
`

Layout.Main = styled.div`
  padding: 0 20px;
`

export const Heading = styled.div`
  font-size: 14px;
  font-weight: bold;
  line-height: 40px;
`
