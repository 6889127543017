import React, { useState } from "react"
import Security from "./sections/Security"
import Profile from "./sections/Profile"
import { Modal, Content } from "./styles"

function Settings ({ onClose }) {
  const [activeSection, setActiveSection] = useState("profile")

  return (
    <Modal
      breadcrumbs={[{
        label: "Security",
        active: activeSection === "security",
        onClick: () => setActiveSection("security"),
      }, {
        label: "Account",
        active: activeSection === "profile",
        onClick: () => setActiveSection("profile"),
      }]}
      onClose={onClose}
    >
      <Content>
        {activeSection === "security" && (
          <Security onSubmit={onClose} />
        )}
        {activeSection === "profile" && (
          <Profile onSubmit={onClose} />
        )}
      </Content>
    </Modal>
  )
}

export default Settings
