import React, { useContext } from "react"
import Choice from "components/inputs/Choice"
import { Context as AppContext } from "AppContext"
import MenuButton from "components/MenuButton"
import { List, Item, ItemRow, ItemColumn } from "components/List"
import { Image } from "components/Image"
import TextField from "components/inputs/Textfield"
import {
  Content,
  Description,
  Title,
} from "./styles"

function AssetList ({
  items = [],
  EmptyComponent,
}) {
  const { actions, menuOptions } = useContext(AppContext)

  const visibleItems = items.filter(i => i.state !== "archived")

  if (!items.length) return EmptyComponent || null

  return (
    <List spacing="default">
      {visibleItems.map(item => (
        <Item key={item.id}>
          <ItemRow data-css-no-min-height>
            <ItemColumn>
              <Choice
                checked={item.completed}
                onClick={() => actions.attachment.updateVars({
                  id: item.id,
                  completed: !item.completed,
                })}
              />
            </ItemColumn>
            <ItemColumn>
              <Image
                primary={item.primary}
                source={item.thumbnail}
                loading={item.loading}
              />
            </ItemColumn>
            <ItemColumn flex>
              <Content>
                <Title>
                  <TextField
                    value={item.label}
                    onSubmit={(label) => actions.attachment.updateVars({
                      id: item.id,
                      label,
                    })}
                    placeholder="type to add a label"
                  />
                </Title>
                <Description>
                  <TextField
                    value={item.description}
                    multiline
                    onSubmit={(description) => actions.attachment.updateVars({
                      description,
                      id: item.id,
                    })}
                    placeholder="type to add a description"
                  />
                </Description>
              </Content>
            </ItemColumn>
            {!item.loading && (
              <ItemColumn>
                <MenuButton
                  items={[menuOptions.archiveAttachment({ assetId: item.id })]}
                />
              </ItemColumn>
            )}
          </ItemRow>
        </Item>
      ))}
    </List>
  )
}

export default AssetList
