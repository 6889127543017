import { useTasks } from "smartHooks/tasks"
import { useAspects } from "smartHooks/aspects"
import { useAttachments } from "smartHooks/attachments"
import { useNavigation } from "smartHooks/navigation"
import SettingsIcon from "@material-ui/icons/Settings"
import StarBorderIcon from "@material-ui/icons/StarBorder"
import ExitToAppIcon from "@material-ui/icons/ExitToApp"
import DescriptionIcon from "@material-ui/icons/Description"
import ImportContactsOutlinedIcon from "@material-ui/icons/ImportContactsOutlined"
import FolderOpenIcon from "@material-ui/icons/FolderOpen"

const useMenuOptions = ({ getParams }) => {
  const taskActions = useTasks({ getParams })
  const aspectActions = useAspects({ getParams })
  const attachmentActions = useAttachments({ getParams })
  const navigation = useNavigation({ getParams })

  const menuOptions = {
    settings: ({ onClick }) => ({
      Icon: SettingsIcon,
      label: "Settings",
      onClick,
    }),

    logout: () => ({
      Icon: ExitToAppIcon,
      label: "Logout",
      onClick: () => {
        localStorage.setItem("auth-token", "")
        navigation.login()
      },
    }),

    addAspect: ({ callback, position = 0, target, type = "aspect" }) => ({
      Icon: type === "aspect" ? DescriptionIcon : FolderOpenIcon,
      label: `Add ${type === "aspect" ? "Aspect" : "Group"}`, // at position ${position}`,
      onClick: () => aspectActions.addVars({
        type,
        label: `New ${type === "aspect" ? "Aspect" : "Group"}`,
        parentId: target.id,
        position,
      }, callback),
    }),

    aspectDetails: (asp) => ({
      Icon: ImportContactsOutlinedIcon,
      label: "Details",
      onClick: () => navigation.aspectDetail({
        id: asp.id,
      }),
    }),

    clearAspectField: ({ aspectId, fieldName, id }) => ({
      label: "Clear",
      icon: "removeAll",
      onClick: () => {
        aspectActions.updateVars({
          id: aspectId || id,
          [fieldName]: "",
        })
      },
    }),

    archiveAspect: (asp) => ({
      icon: "remove",
      label: "Archive",
      onClick: () => aspectActions.updateVars({
        state: "archived",
        id: asp.id,
      }),
    }),

    archiveAspectAttachments: (asp) => ({
      label: "Archive All Tasks",
      icon: "archive",
      onClick: () => attachmentActions.updateVars({
        aspectId: asp.id,
        state: "archived",
        primary: false,
      }),
    }),

    archiveTask: (task) => ({
      label: "Archive",
      icon: "archive",
      onClick: () => taskActions.archiveVars(task),
    }),

    addTaskAttachment: ({ upload }) => ({
      label: "Attach...",
      icon: "paperclip",
      onClick: () => upload(),
    }),

    attachAsset: ({ upload }) => ({
      label: "Attach Asset",
      icon: "paperclip",
      onClick: () => upload(),
    }),

    archiveAttachments: ({ aspectId, taskId }) => ({
      label: "Archive All",
      icon: "archiveAll",
      onClick: () => attachmentActions.updateVars({
        taskId,
        aspectId,
        state: "archived",
      }),
    }),

    archiveTaskAttachments: ({ taskId }) => ({
      label: "Archive All",
      icon: "archiveAll",
      onClick: () => attachmentActions.updateVars({
        taskId,
        state: "archived",
      }),
    }),

    clearAttachment: ({ attachmentId }) => ({
      label: "Unstar",
      Icon: StarBorderIcon,
      onClick: () => attachmentActions.updateVars({
        id: attachmentId,
        primary: false,
      }),
    }),

    archiveAttachment: ({ assetId, attachmentId }) => ({
      label: "Archive",
      icon: "archive",
      onClick: () => attachmentActions.archiveVars({
        attachmentId: assetId || attachmentId,
      }),
    }),
  }

  return menuOptions
}

export {
  useMenuOptions,
}
