import React from "react"
import { MenuButton } from "components/MenuButton"
import {
  Wrapper,
  Content,
  Header,
  HeaderLabel,
  HeaderOptions,
  HeaderOption,
} from "./styles"

function Section ({
  children,
  heading,
  options = [],
}) {
  return (
    <Wrapper>
      {heading && (
        <Header>
          <HeaderLabel>
            {heading}
          </HeaderLabel>
          {options.length > 0 && (
            <HeaderOptions>

              <HeaderOption>
                <MenuButton items={options} />
              </HeaderOption>
            </HeaderOptions>
          )}
        </Header>
      )}
      <Content>
        {children}
      </Content>
    </Wrapper>
  )
}

export default Section
