// TODO: revisit + refactor context setup
// TODO: make sure rerenders aren't happening with hooks
import React, { useMemo } from "react"
import gql from "graphql-tag"
import { useTasks } from "smartHooks/tasks"
import { useAspects } from "smartHooks/aspects"
import { useApolloClient, useQuery } from "@apollo/client"
import { useAttachments } from "smartHooks/attachments"
import { useMenuOptions } from "smartHooks/menuOptions"
import { useNavigation } from "smartHooks/navigation"
import { useNavigate, useParams } from "react-router-dom"

const GET_CURRENT_USER_QUERY = gql`
  query GetCurrentUser {
    me {
      firstName
      lastName
      email
    }
  }
`

const Context = React.createContext({})

function AppContext ({ children }) {
  const navigate = useNavigate()
  const apolloClient = useApolloClient()
  const { action, aspectId, projectId } = useParams()
  const userResult = useQuery(GET_CURRENT_USER_QUERY)

  const getParams = () => ({
    navigate,
    action,
    aspectId: aspectId || projectId,
    projectId,
    client: apolloClient,
    currentAspect: aspectId || projectId,
    user: userResult?.data?.me,
  })

  const taskActions = useTasks({ getParams })
  const aspectActions = useAspects({ getParams })
  const attachmentActions = useAttachments({ getParams })
  const menuOptions = useMenuOptions({ getParams })
  const navigation = useNavigation({ getParams })

  const context = useMemo(() => ({
    actions: {
      aspect: aspectActions,
      task: taskActions,
      attachment: attachmentActions,
    },
    navigation,
    menuOptions,
    getParams,
  }))

  context.context = {
    ...context,
  }

  // TODO: improve loading indicator
  if (userResult.loading) return null

  // TODO: add preloading to user
  if (!userResult?.data?.me) {
    navigate("/auth/login")
  }

  return (
    <Context.Provider value={context}>
      {children}
    </Context.Provider>
  )
}

export { AppContext, Context }

export default AppContext
