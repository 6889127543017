import React, { useContext, useRef, useLayoutEffect } from "react"
import AspectBoardContext from "components/AspectBoard/Context"
import { PopMenu } from "components/PopMenu"
import { Context } from "AppContext"
import AspectTree from "components/AspectTree"
import { Heading } from "./Heading"

import {
  Wrapper,
  Layout,
  VBetween,
  Content,
  LaneButton,
} from "./styles"

function Lane ({
  aspect,
  index,
}) {
  const headerRef = useRef()
  const { aspectIdPendingFocus, setAspectIdPendingFocus } = useContext(AspectBoardContext)
  const { menuOptions } = useContext(Context)

  // const handleAspectAdd = (variables) => {
  // actions.aspect.addVars(variables, ({ id }) => {
  // setAspectIdPendingFocus(id)
  // })
  // }

  useLayoutEffect(() => {
    if (headerRef.current && aspectIdPendingFocus === aspect.id) {
      headerRef.current.focus()
      headerRef.current.setRange()
      setAspectIdPendingFocus(null)
    }
  }, [aspectIdPendingFocus])

  return (
    <Wrapper key={aspect.id}>
      {index === 0 && (
        <PopMenu
          anchor="mouseY"
          items={[[
            menuOptions.addAspect({
              target: { id: aspect.parentId },
              position: aspect.position - 1,
            }),
            menuOptions.addAspect({
              target: { id: aspect.parentId },
              position: aspect.position - 1,
              type: "group",
            }),
          ]]}
        >
          <VBetween data-css-first={index === 0} />
        </PopMenu>
      )}
      <Layout>
        <Layout.Top>
          <Heading
            aspect={aspect}
            ref={headerRef}
          />
        </Layout.Top>
        <Layout.Bottom>
          <Content data-css-empty={aspect.aspects?.length === 0}>
            {aspect.aspects?.map((child, idx) => (
              <AspectTree
                aspect={child}
                displayTasks
                key={child.id}
                index={idx}
                top
              />
            ))}
            <PopMenu
              anchor="mouse"
              items={[
                menuOptions.addAspect({
                  target: { id: aspect.id },
                  position: aspect.position + 1,
                }),
                menuOptions.addAspect({
                  target: { id: aspect.id },
                  position: aspect.position + 1,
                  type: "group",
                }),
              ]}
            >
              <LaneButton />
            </PopMenu>
          </Content>
        </Layout.Bottom>
      </Layout>
      <PopMenu
        anchor="mouseY"
        items={[[
          menuOptions.addAspect({
            target: { id: aspect.parentId },
            position: aspect.position + 1,
          }),
          menuOptions.addAspect({
            target: { id: aspect.parentId },
            position: aspect.position + 1,
            type: "group",
          }),
        ]]}
      >
        <VBetween />
      </PopMenu>
    </Wrapper>
  )
}

export default Lane
