import styled from "styled-components"

export const Content = styled.div``

export const Description = styled.div`
  font-size: 12px;
  line-height: 16px;
`

export const Title = styled.div`
  font-size: 16px;
  line-height: 24px;
`
