import styled from "styled-components"

export const Wrapper = styled.div`
  display: flex; 
  align-content: center;
  align-items: center;
  height: 60px;
  padding: 0 30px;
  font-size: 18px;
  font-weight: 400;
  line-height: 60px;
  color: var(--text-color);
  background: var(--panel-background-color);
  border-bottom: solid 1px var(--border-subdued-color);

  svg {
    font-size: 16px
  }
`

export const Label = styled.div`
  &:hover {
    text-decoration: underline;
    cursor: default;
  }

  &:not(:first-child) {
    padding-left: 10px;
  }

  &:not(:last-child) {
    padding-right: 10px;
  }

  svg {
    vertical-align: middle;
  }
`
