import styled from "styled-components"

export const AddButton = styled.div`
  width: 20px;
  height: 20px;
  color: var(--text-subdued-color);

  svg {
    font-size: 20px;
    vertical-align: top;
  }
`
