import styled from "styled-components"

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-grow: 1;

  textarea {
    padding-top: 5px;
    padding-bottom: 5px;
  }
    
  textarea,
  input {
    position: relative;
    flex: 1 1 auto;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    color: inherit;
    vertical-align: top;
    cursor: default;
    resize: none;
    background: transparent;
    outline: solid 1px transparent;
    box-shadow: 0px 0px transparent;

    &:focus {
      position: relative;
      z-index: 100;
      padding-right: 5px;
      padding-left: 5px;
      margin-right: -5px;
      margin-left: -5px;
      color: inherit;
      cursor: text;
      background: white;
      outline: solid 1px var(--border-subdued-color);
      box-shadow: 0 1px 6px #B0C9FA99;
      box-shadow: 3px 3px var(--panel-shadow-color);
      transition: box-shadow 200ms, outline 200ms;

      outline: solid 1px var(--border-hard-color);
      box-shadow: 0 0 8px 0 rgba(176,201,250,0.29);
    }
    
    &::placeholder {
      color: var(--text-subdued-color);
    }

    & {
      ${p => p.strike === true && `
        text-decoration: line-through;
      `}
    }
  }
`
