import styled from "styled-components"
import Textfield from "components/inputs/Textfield"

export const Wrapper = styled.div`
  padding: 0 10px 0 20px;
  font-size: 16px;

  &:not(:last-child) {
    border-bottom: solid 1px var(--border-subdued-color);
  }
`

export const TextField = styled(Textfield)`
  min-height: 40px;
`

export const Layout = styled.div`
  display:flex;
`

Layout.Left = styled.div`
  flex: 0 0 auto;
  padding-top: 10px;

  svg {
    font-size: 20px;
    vertical-align: top;
  }
`

Layout.Main = styled.div`
  flex: 1 0 auto;
  padding-left: 10px;

  textarea {
    padding-top: 9px;
    padding-bottom: 9px;
    line-height: 22px;
  }
`

Layout.Right = styled.div`
  display: flex;
  flex: 0 1 auto;
  align-items: flex-start;
  justify-content: center;
  margin-left: 5px;

  svg {
    font-size: inherit;
    font-size: 20px;
    vertical-align: middle;
  }
`

export const Actions = styled.div`
  padding-top: 10px;
`

export const Action = styled.div`
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;

  &[data-css-active="true"],
  &:hover {
    background: var(--panel-hover-color);
    border-radius: 4px;
    opacity: 1;
  }

  ${/* sc-prop */ Wrapper}:hover & {
    opacity: 1;
  }

  svg {
    font-size: 16px;
    vertical-align: top;
  }

`
