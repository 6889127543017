import styled from "styled-components"

export const Wrapper = styled.div`
  display: flex;
  flex-shrink: 1;
  align-items: center;
  align-self: center;
  justify-self: center;
  height: 100%;
  padding: 40px;
  margin: auto;
`

export const ImageWrapper = styled.div`
  height: 100%;
  max-height: 100%;
  box-shadow: 0 0 14px 2px rgba(0,0,0,0.50);
`

export const Image = styled.img`
  width: auto;
  height: 100%;
  max-height: 100%;
  vertical-align: center;
  vertical-align: top;
  border: none;
`
