/* eslint-disable */
import React, { useContext } from "react"
import FolderIcon from "@material-ui/icons/FolderOutlined"
import ArrowRight from "@material-ui/icons/ArrowRight"
import MoreVertIcon from "@material-ui/icons/MoreVert"
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown"
import PopMenu from "components/PopMenu"
import useToggle from "hooks/useToggle"
import { Context } from "AppContext"

import {
  Wrapper,
  Label,
  MenuButton,
  ToggleButton,
  ArrowWrapper,
  // Between,
} from "./styles"

const flatten = (aspect) => {
  if (aspect.type !== "group") return []

  let labels = [aspect.label]

  for (let i = 0; i < aspect.aspects.length; i++) {
    const child = aspect.aspects[i]
    labels = [...labels, ...flatten(child)]
  }

  return labels
}

function Item ({
  SubTreeElement,
  activeId,
  aspect,
  filter,
  onAspectAdd,
  onAspectBeginEdit,
  onAspectUpdate,
  onItemClick,
}) {
  const { menuOptions, navigation } = useContext(Context)

  const subMenuToggler = useToggle(aspect.level <= 1)

  if (aspect.type !== "project" && aspect.type !== "group") return null

  const hasSubGroups = aspect.aspects.some(a => a.type === "project" || a.type === "group" || a.aspects.length > 0)

  const isFiltering = !!filter
  // let matchesFilter
  let hasChildrenMatchingFilter

  if (filter) {
    const flattenedLabels = flatten(aspect)
    const filterRegExp = new RegExp(filter, "ig")
    hasChildrenMatchingFilter = flattenedLabels.some(fl => filterRegExp.test(fl))
  }

  return (
    <div
      role="item"
      onClick={() => {
        // if this item is clicked while filtering, expanded it automatically
        if (filter) {
          subMenuToggler.on()
        }
      }}
    >
      <PopMenu
        items={[
          menuOptions.addAspect({
            target: aspect,
            type: 'group',
            position: 9999
          }),
          menuOptions.aspectDetails(aspect),
          menuOptions.archiveAspect(aspect),
        ]}
      >
        {({ active, onClick: showMenu, ref }) => (
          <Wrapper
            level={aspect.level}
            active={activeId === aspect.id}
            menuOpen={active}
            visible={(isFiltering && hasChildrenMatchingFilter) || !isFiltering}
            data-css-active={activeId === aspect.id}
          >
            <ToggleButton onClick={() => subMenuToggler.toggle()}>
              <ArrowWrapper>
                {hasSubGroups && (
                  (subMenuToggler.isOff ? <ArrowRight /> : <ArrowDropDownIcon />)
                )}
              </ArrowWrapper>
              <FolderIcon />
            </ToggleButton>
            <Label onClick={() => navigation.aspectView({ id: aspect.id })}>
              {aspect.label}
            </Label>
            <MenuButton
              ref={ref}
              onClick={showMenu}
            >
              <MoreVertIcon />
            </MenuButton>
          </Wrapper>
        )}
      </PopMenu>
      {(filter || subMenuToggler.isOn) && (
        <SubTreeElement
          level={aspect.level + 1}
          aspects={aspect.aspects}
          filter={filter}
          activeId={activeId}
          onAspectAdd={onAspectAdd}
          onAspectUpdate={onAspectUpdate}
          onAspectBeginEdit={onAspectBeginEdit}
        />
      )}
    </div>
  )
}

export default Item
