import React, { useRef, useState } from "react"
import Overlay from "components/Overlay"

import {
  Wrapper,
  InnerWrapper,
  Items,
  Item,
} from "./styles"

function Tooltip ({
  anchor,
  error,
  children,
  items = [],
  title,
  onShow,
  onHide,
  itemProps,
  offsetY = 0,
}) {
  const ref = useRef()
  const [visible, setVisible] = useState(false)

  const rect = ref.current ? ref.current.getBoundingClientRect() : {}

  const hide = () => {
    setVisible(false)
    if (onHide) onHide()
  }

  const show = () => {
    setVisible(true)
    if (onShow) onShow()
  }

  const getStyle = () => {
    if (anchor === "topRight") {
      return {
        right: `${rect.right}px`,
        top: `${offsetY + rect.top}px`,
      }
    }

    if (anchor === "topLeft") {
      return {
        left: `${rect.left}px`,
        top: `${offsetY + rect.top}px`,
      }
    }

    if (anchor === "bottomLeft") {
      return {
        left: `${rect.left}px`,
        top: `${offsetY + rect.bottom}px`,
      }
    }

    if (anchor === "bottomRight") {
      return {
        right: `${rect.right}px`,
        top: `${offsetY + rect.bottom}px`,
      }
    }

    if (anchor === "mouseX") {
      return {
        left: `${window.currentMouseX}px`,
        top: `${offsetY + rect.top + (rect.height / 2)}px`,
      }
    }

    if (anchor === "mouseY") {
      return {
        left: `${rect.left + (rect.width / 2)}px`,
        top: `${window.currentMouseY}px`,
      }
    }

    if (anchor === "mouse") {
      return {
        left: `${window.currentMouseX}px`,
        top: `${offsetY + window.currentMouseY}px`,
      }
    }

    return {
      left: `${rect.left + (rect.width / 2)}px`,
      top: `${rect.top + (rect.height / 2)}px`,
      transform: "translateX(0%)",
    }
  }

  if (items.length === 0) return children

  return (
    <>
      <>
        {(() => {
          const props = {
            onMouseEnter: () => show(),
            onMouseLeave: (e) => {
              hide()
            },
            "data-css-popup-active": visible,
            ref,
          }

          if (typeof children === "function") {
            return children({
              ...props,
              active: visible,
              menuActive: visible,
              showMenu: () => (!visible ? show() : hide()),
            })
          }

          return React.cloneElement(children, {
            ref,
            ...props,
            className: visible ? "active" : "",
          })
        })()}
      </>

      {visible && (
        <Overlay
          darken={false}
          pointerEvents="none"
          onHide={hide}
        >
          <Wrapper style={getStyle()} anchor={anchor} data-css-error={error}>
            <InnerWrapper>
              <Items>
                {items.map(item => (
                  <Item>
                    <Item.Title>
                      {item.title}
                    </Item.Title>
                    {item.description && (
                      <Item.Description>
                        {item.description.trim()}
                      </Item.Description>
                    )}
                  </Item>
                ))}
              </Items>
            </InnerWrapper>
          </Wrapper>
        </Overlay>
      )}
    </>
  )
}

export default Tooltip
