const useNavigation = ({ getParams }) => {
  const aspectDetail = ({ id }) => {
    const { aspectId, navigate, projectId } = getParams()

    if (aspectId) {
      navigate(`/project/${projectId}/${aspectId}/view/${id}`)
    } else {
      navigate(`/project/${projectId}/view/${id}`)
    }
  }

  const settingsView = () => {
    const { aspectId, navigate, projectId } = getParams()

    if (aspectId) {
      navigate(`/project/${projectId}/${aspectId}/settings`)
    } else {
      navigate(`/project/${projectId}/settings`)
    }
  }

  const aspectView = ({ id }) => {
    const { navigate, projectId } = getParams()
    navigate(`/project/${projectId}/${id}`)
  }

  const projectView = ({ id }) => {
    const { navigate } = getParams()
    navigate(`/project/${id}`)
  }

  const boardView = () => {
    const { aspectId, navigate, projectId } = getParams()
    if (aspectId) {
      navigate(`/project/${projectId}/${aspectId}`)
    } else {
      navigate(`/project/${projectId}`)
    }
  }

  const login = () => {
    const { navigate } = getParams()
    navigate("/auth/login")
  }

  return {
    aspectDetail,
    aspectView,
    projectView,
    boardView,
    settingsView,
    login,
  }
}

export {
  useNavigation,
}
