import styled from "styled-components"

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  padding: 0 5px;
  font-size: 10px;
  color: var(--text-subdued-color);
  background-color: var(--panel-background-color);
  border-radius: 2px;
  transition: opacity 200ms;

  &:hover {
    background-color: var(--panel-hover-color);
  }

  svg {
    font-size: inherit;
    font-size: 16px;
    vertical-align: middle
  }

  & {
    ${p => p && p.onlyIcon && `
      padding: 0;
   `}

    ${p => p && p.naked && `
      background-color: none;
   `}

    ${p => p && p.visible && `
      opacity: 1
   `}

    ${p => `
      ${p.containerSC}:hover {
        opacity: 1;
      }
   `}

    ${p => p && p.active && `
      opacity: 1;
      background-color: var(--panel-hover-color);
   `}
  }
`
