import React, { useContext } from "react"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
// import HomeIcon from "@material-ui/icons/Home"
import { Context } from "AppContext"
import {
  Wrapper,
  Label,
} from "./styles"

function Heading ({
  breadcrumbs = [],
}) {
  const { onBreadcrumbClick } = useContext(Context)

  return (
    <Wrapper>
      {breadcrumbs.map((crumb, index) => (
        <React.Fragment key={crumb.id}>
          <ChevronRightIcon />
          <Label onClick={() => onBreadcrumbClick(crumb.id)}>
            {index === 0 ? "Home" : crumb.label}
          </Label>
        </React.Fragment>
      ))}
    </Wrapper>
  )
}

export default Heading
