import styled from "styled-components"

export const Layout = styled.div`
  display: flex;
  flex: 1 1 auto;
  height: 100%;
  overflow: hidden;
`

Layout.ProjectBar = styled.div`
  position: relative;
  flex: 0 0 auto;
  width: 60px;
`

Layout.Navigation = styled.div`
  position: relative;
  flex: 0 0 auto;
  width: 280px;
`

Layout.Main = styled.div`
  position: relative;
  flex: 1 1 auto;
  display: flex;
`
