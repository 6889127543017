import React, { useLayoutEffect, useState } from "react"
import update from "immutability-helper"

const checkSound = require("./drop.mp3")

const players = new Array(3).fill(null).map(
  () => new window.Audio(checkSound)
)

const ListSortableItems = ({
  children,
  onSort,
}) => {
  const [items, setItems] = useState([])

  useLayoutEffect(() => {
    const newItems = (React.Children.toArray(children))
    // const lastPositions = items.map(i => i.props.position + i.props.id)
    // const newPositions = newItems.map(i => i.props.position + i.props.id)
    // const same = (newPositions.toString() === lastPositions.toString())
    // console.log(same, lastPositions, newPositions);
    // if (!same) {
    setItems(newItems)
    // }
  }, [children])

  const moveItem = (id, atIndex) => {
    const { index, item } = findItem(id)
    setItems(update(items, {
      $splice: [
        [index, 1],
        [atIndex, 0, item],
      ],
    }))
  }

  const getItemByIndex = (index) => items[index]

  const findItem = (id) => {
    const item = items.filter((i) => `${i.props.id}` === id)[0]
    return {
      item,
      id: item.props.id,
      index: items.indexOf(item),
    }
  }

  const handleSort = (movedId) => {
    const freePlayer = players.find(p => p.paused) || players[0]
    freePlayer.play()

    const item = findItem(movedId)

    // cacheItems();

    // return;
    onSort({
      id: item.id,
      newPosition: item.index,
      oldPosition: React.Children
        .toArray(children)
        .find(c => c.props.id === item.id)?.props?.position,
    })
  }

  // return null;
  return items.map(
    child => React.cloneElement(child, {
      dndType: "item",
      sortable: true,
      onSort: handleSort,
      findItem,
      moveItem,
      getItemByIndex,
      ...child.props,
    }))
}

export default ListSortableItems
