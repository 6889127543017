import styled from "styled-components"
import TransientTextField from "components/inputs/Textfield"
import { Modal as BaseModal } from "components/modals/Base"
import BaseSectionHeader from "components/SectionHeader"

export const Modal = styled(BaseModal)`
  width: 360px;
`

export const Content = styled.div`
  padding: 30px;
`

export const SectionHeader = styled(BaseSectionHeader)`
  margin-bottom: 20px;
`

export const Layout = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

Layout.Main = styled.div`
  display: grid;
  flex: 1 0 auto;
  grid-area: main;
  grid-auto-rows: min-content;
  grid-row-gap: 60px;
  padding: 30px;
  overflow: auto
`

Layout.Side = styled.div`
  display: flex;
  flex: 0 1 auto;
  grid-area: side;
  align-items: stretch;
  width: 200px;
  border-left: solid 1px var(--border-subdued-color);
`

export const TextField = styled(TransientTextField).attrs({
  spellCheck: false,
  multiline: true,
})`
  font-size: 16px;
  line-height: 22px;

  textarea {
    padding: 10px 0;

    &:focus {
      padding-right: 10px;
      padding-left: 10px;
      margin-right: -10px;
      margin-left: -10px;
    }
  }
`
