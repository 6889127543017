/* eslint-disable */
export const pipe = (data, functions) => {
  if (data && functions) {
    return functions.reduce(
      (value, func) => func(value),
      data
    )
  }
  return (data2) => functions.reduce(
    (value, func) => func(value),
    data2
  )
}

export const duration = (baseSecs) => {
  const hours = (baseSecs / 60 / 60)

  if (hours === 0) return "0 hours"

  if (hours < 1) {
    return `${hours * 60} min`
  } if (hours === 1) {
    return `${hours} hour`
  }

  return `${hours} hours`
}

export function snapToGrid (x, y) {
  const snappedX = Math.round(x / 32) * 32
  const snappedY = Math.round(y / 32) * 32
  return [snappedX, snappedY]
}

export const mergeRefs = (...refs) => {
  const filteredRefs = refs.filter(Boolean)
  if (!filteredRefs.length) return null
  if (filteredRefs.length === 0) return filteredRefs[0]
  return inst => {
    filteredRefs.forEach(ref => {
      if (typeof ref === "function") {
        ref(inst)
      } else if (ref) {
        ref.current = inst
      }
    })
    //for (const ref of filteredRefs) {
      //if (typeof ref === "function") {
        //ref(inst)
      //} else if (ref) {
        //ref.current = inst
      //}
    //}
  }
}
