import styled from "styled-components"

export const Wrapper = styled.div`
display: flex;
flex-direction: column;
padding: 20px 0;
font-size: 14px;
  
&:not(:first-child) {
  border-top: solid 1px var(--border-subdued-color);
}

${p => !p.flex && `
    flex-shrink: 0
  `}

${p => p.flex && `
    flex-grow: 1
  `}
`

export const Content = styled.div`
position: relative;
display: flex;
justify-content: stretch;

> * {
  flex-grow: 1;
}
`

export const Header = styled.div`
display: flex;
flex-grow: 0;
margin-bottom: 5px;
font-size: 14px;
font-weight: 600;
line-height: 20px;
`

export const HeaderLabel = styled.div` 
flex-grow: 1;
flex-shrink: 0;
cursor: pointer;
`

export const HeaderOptions = styled.div`
flex-grow: 0;
flex-shrink: 1
`

export const HeaderOption = styled.div`
svg {
  font-size: 14px;
  vertical-align: middle;
}
`
