import styled from "styled-components/macro"

export const Wrapper = styled.div`
  display: inline-flex;
  flex-grow: 1;
  min-width: 100%;
  width: 100%;
  height: 100%;
  vertical-align: top;
  overflow: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`

export const Scroller = styled.div`
  display: flex;
  flex-grow: 1;
`
