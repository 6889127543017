import React, { useContext } from "react"
import { Context as AppContext } from "AppContext"
import Section from "components/Section"
import { TextField } from "./styles"

function DescriptionSection ({ aspect }) {
  const { actions, menuOptions } = useContext(AppContext)

  return (
    <Section
      headerOptions={{
        label: "Description",
      }}
      menu={{
        items: [menuOptions.clearAspectField({
          aspectId: aspect.id,
          fieldName: "description",
        })],
      }}
    >
      <TextField
        value={aspect.description || ""}
        onSubmit={(description) => {
          actions.aspect.updateVars({
            id: aspect.id,
            description,
          })
        }}
        placeholder="start typing to add a description"
      />
    </Section>

  )
}

export default DescriptionSection
