import React from "react"
import {
  Wrapper,
  Ticker,
  Label,
} from "./styles"

function Item ({
  active,
  index,
  onClick,
  project,
}) {
  return (
    <Wrapper
      onClick={onClick}
      style={{ animationDelay: `.${index}s` }}
      data-css-active={active}
    >
      <Ticker>{project?.label?.substring(0, 3) || "???"}</Ticker>
      <Label>{project?.label || "???"}</Label>
    </Wrapper>
  )
}

export default Item
