import { css, keyframes } from "styled-components"

export const backgroundColor = "#F0F4FD"

export const normalRadius = "8px"
export const innerRadius = "6px"
export const animationTiming = 200

export const fontColorOnBackground = "#2B3D7F"
export const fontColorOnBackgroundAlt = "#2765B2"
export const normalShadow = "0 0 8px 0 rgba(176,201,250,0.29);"

export const cardBackgroundColor = "white"
export const cardFontColor = "#2765B2"
export const cardRadius = normalRadius
export const cardShadow = "0 0 8px 0 rgba(176,201,250,0.29)"
export const cardAltBackgroundColor = "#FAFBFE"
export const cardBorderNormalToAlt = "#F0F4FD"
export const cardAltFontColor = "#2765B2"

export const card = {
  backgroundColor: "white",
  fontColor: "#2765B2",
  radius: normalRadius,
  // shadow: "0 0 8px 0 rgba(176,201,250,0.29)",
  altBackgroundColor: "#FAFBFE",
  borderNormalToAlt: "#F0F4FD",
  altFontColor: "#2765B2",
}

export const borderWhiteToWhite = "#F0F4FD"

export const contextBar = {
  backgroundColor: "#2765B2",
  fontColor: "#FAFBFE",
}

export const contextBarItem = {
  fontColor: "#FAFBFE",
  borderRadius: "4px",
  borderColor: "#658FDA",
  activeBackgroundColor: "#658FDA",
}

export const animations = {
  appear: keyframes`
    0% { opacity: 0; }
    100% { opacity: 1; }
  `,
}

// eslint-disable-next-line
let mixins = {};

mixins = {
  appear: css`
    opacity: 0;
    animation-name: animate-in;
    animation-iterations: 1;
    animation: ${animations.appear} 400ms ease-in-out ;
    animation-fill-mode: forwards;
  `,

  flexCenter: css`
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
  `,

  card: css`
    background: white;
    border-radius: ${normalRadius};
    //box-shadow: ${normalShadow};
    border: 1px solid #D4E0F4;
    color: #2B3D7F;
    position: relative;
    transition: background 200ms;
  `,

  cardHover: css`
    &:hover {
      background: #fff2;
    }
  `,

  altCard: css`
    ${mixins.card};

    //color: #ccc
    background: #FAFBFE;
  `,

  menuButton: () => css`
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-content: center;
    transition: background 200ms;

    &.active,
    &:hover {
      background: #D4E0F4;
      opacity: 1;
    }
  `,
}

export { mixins }
