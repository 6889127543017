import React, { forwardRef } from "react"
import MoreVertIcon from "@material-ui/icons/MoreVert"
import PopMenu from "components/PopMenu"
// import Tooltip from "components/Tooltip"
import { Wrapper } from "./styles"

function MenuButton ({
  Icon = MoreVertIcon,
  active,
  children,
  containerSC,
  label,
  items = [],
  naked,
  onClick,
  // tooltip,
}, ref) {
  return (
    <PopMenu items={items}>
      <Wrapper
        ref={ref}
        active={active}
        containerSC={containerSC}
        naked
        onClick={onClick}
      >
        {Icon && <Icon />}
        {label || children}
      </Wrapper>
    </PopMenu>
  )
}

// <Tooltip {...tooltip} anchor="bottomLeft" offsetY={5}>
// </Tooltip>

export default forwardRef(MenuButton)
