import styled from "styled-components"
import { Form as FormikForm } from "formik"

export const Form = styled(FormikForm)``

Form.Rows = styled.div`
  &:not(:last-child) {
    margin-bottom: 20px;
  }
`

Form.Row = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-auto-flow: column;

  &:not(:last-child) {
    margin-bottom: 20px;
  }
`

Form.Col = styled.div``

Form.Actions = styled.div``

Form.Action = styled.div``

Form.Button = styled.button`
  display: block;
  width: 100%;
  height: 50px;
  font-size: 16px;
  font-weight: 400;
  line-height: 50px;
  color: white;
  text-align: center;
  background-color: var(--button-color);
  border-radius: 4px;
  transition: all 200ms;

  &:hover {
    cursor: pointer;
    background-color: var(--button-color-hover);
  }
`
