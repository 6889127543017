import styled from "styled-components"

export const Wrapper = styled.div`
  transition: all 200ms;
  border-radius: 0 0 8px 8px;

  &[data-css-editing="true"] {
    background: var(--panel-subdued-background-color);
  }
`

export const Items = styled.div`
  &:not(:last-child) {
    border-bottom: solid 1px var(--border-subdued-color);
  }
`

export const Actions = styled.div`
  display: flex;
  height: 40px;
`

export const Action = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 0 auto;
  transition: background 200ms;
  color: var(--text-subdued-color);

  &:hover {
    background: var(--panel-subdued-background-color);
    color: var(--text-color);
  }

  svg {
    font-size: 20px;
  }
`
