import styled, { keyframes } from "styled-components"

const show = keyframes`
  from {
    opacity: 0;
    transform: scale(.95) translateX(0)
  }

  to {
    opacity: 1;
    transform: scale(1) translateX(0)
  }
`

export const Wrapper = styled.div`
  position: absolute;
  align-self: flex-start;
  justify-self: flex-start;
  min-width: 160px;
  font-size: 14px;
  font-weight: normal;
  color: #666;
  background: white;
  animation: ${show} 100ms;

  &:before {
    position: absolute;
    top: -5px;
    left: -5px;
    display: none;
    width: 10px;
    height: 10px;
    content: '';
    background: #D4E0F4;
    border: solid 1px #D4E0F4;
    border-radius: 100%;
    transition: all 200ms;
    transform: scale(0);
  }

  & {
    ${p => (p.anchor && p.anchor.indexOf("mouse") !== -1) && `
      &:before {
        transform: scale(1)
      }
    `}
  }
`

export const InnerWrapper = styled.div`
  position: relative;
  z-index: 1;
  overflow:hidden;
  border: 1px solid var(--border-hard-color);
  box-shadow: 0 0 8px 0 rgba(176,201,250,0.29);
`

export const ItemGroups = styled.div``

export const ItemGroup = styled.div`
  &:not(:first-child) {
    border-top: solid 1px #fff6;
  }
`

export const Title = styled.div`
  display: none;
  height: 20px;
  padding: 0 10px;
  font-size: 10px;
  line-height: 20px;
  color: #bbb;
  border-radius: 4px;
`

export const Items = styled.div`
  padding: 5px 0;
`

export const Item = styled.div`
  display: flex;
  height: 40px;
  padding: 0 20px;
  font-weight: normal;
  line-height: 40px;
  color: #2B3D7F;
  background: white;
  transition: background-color 100ms;

  &:hover {
    cursor: default;
    background: #F0F4FD;
  }

  &:active {
    transform: scale(.98)
  }
`

Item.Label = styled.div`
  line-height: 41px
`

Item.Icon = styled.div`
  margin-right: 5px;

  svg {
    font-size: 16px;
    vertical-align: middle;

    & {
      ${p => p.rotate && (`
        transform: rotateZ(${p.rotate}deg);
      `)}
    }
  }

`
