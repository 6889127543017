import React from "react"
import SearchIcon from "@material-ui/icons/Search"
import CloseIcon from "@material-ui/icons/Close"

import {
  Wrapper,
  Layout,
  Input,
  Button,
} from "./styles"

function Filter ({
  onCancel,
  onChange,
  value,
}) {
  return (
    <Wrapper>
      <Layout>
        <Layout.Left>
          <Input
            placeholder="Type to filter"
            value={value}
            onChange={onChange}
          />
        </Layout.Left>
        <Layout.Right>
          {value
            ? (
              <Button>
                <CloseIcon onClick={onCancel} />
              </Button>
            )
            : (
              <Button>
                <SearchIcon />
              </Button>
            )}
        </Layout.Right>
      </Layout>
    </Wrapper>
  )
}

export default Filter
