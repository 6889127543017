import React, { useContext, useEffect, useState, useMemo } from "react"
import { useLazyQuery } from "@apollo/client"
import * as queries from "queries/aspect_queries"
import { Context as AppContext } from "AppContext"
import { Header } from "./Header"
import { Layout, Modal } from "./styles"
import { Side } from "./Side"
import { PlanSection } from "./Main/PlanSection"
import { DescriptionSection } from "./Main/DescriptionSection"
import { AttachmentsSection } from "./Main/AttachmentsSection"
import { makeActions } from "./helpers"
import { AspectDetailContext } from "./AspectDetailContext"

function AspectModal ({ id }) {
  const { context: appContext, navigation } = useContext(AppContext)
  const [breadcrumb, setBreadcrumb] = useState([])
  const [aspect, setAspect] = useState()
  const context = useMemo(() => aspect, [aspect])

  const [getData, {
    called,
    error,
    loading,
  }] = useLazyQuery(queries.GET_ASPECT_QUERY)

  useEffect(() => {
    getData({
      variables: { id },
      onCompleted: (result) => {
        if (!called) {
          setBreadcrumb(result.aspect.breadcrumb)
        }
        setAspect(result?.aspect)
      },
    })
  }, [id])

  const actions = useMemo(() => (aspect ? makeActions(aspect, appContext) : []), [aspect])

  if (!aspect) return null

  return (
    <AspectDetailContext.Provider value={context}>
      <Modal
        actions={actions}
        loading={loading}
        error={error}
        breadcrumbs={
          breadcrumb.map(b => ({
            label: b.label,
            active: b.id === aspect.id,
            onClick: () => navigation.aspectDetail({ id: b.id }),
          }))
        }
        onClose={() => navigation.boardView({ id: aspect.id })}
      >
        {!loading && aspect && (
          <Layout>
            <Layout.Main>
              <Header aspect={aspect} />
              <DescriptionSection aspect={aspect} />
              <PlanSection aspect={aspect} />
              <AttachmentsSection aspect={aspect} />
            </Layout.Main>
            <Layout.Side>
              <Side aspect={aspect} />
            </Layout.Side>
          </Layout>
        )}
      </Modal>
    </AspectDetailContext.Provider>
  )
}

export default AspectModal
