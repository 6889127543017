import styled, { keyframes } from "styled-components"
import { LaneButton as BaseLaneButton } from "./LaneButton"

const show = keyframes`
  0% {
    opacity: 0; 
  }

  100% {
    opacity: 1; 
  }
`

export const Wrapper = styled.div`
  position: relative;
  flex: 0 1 auto;
  min-width: 20px; 
  height: 100%;
  min-width: 360px;

  &:last-child {
    flex-grow: 1;
  }
`

export const Layout = styled.div`
  position: relative;
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
`

Layout.Top = styled.div`
  position: relative;
  z-index: 10;
  flex: 0 0 auto;
`

Layout.Bottom = styled.div`
  position: relative;
  flex: 1 1 auto;
  overflow: hidden;
`

export const Content = styled.div`
  position: relative;
  height: 100%;
  padding: 30px 30px 90px;
  overflow: auto;

  &[data-css-center] {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

export const VBetween = styled.div`
  position: absolute;
  top: 80px;
  bottom: 30px;
  z-index: 1;
  display: flex;
  justify-content: center;
  width: 30px;
  background: transparent;

  &:hover {
    width: 30px;
  }

  &:after {
    display: block;
    width: 0px;
    height: 100%;
    content: '';
    border-right: solid 1px #D4E0F4;
    opacity: 0;
    transition: all 200ms;
    transition-delay: 200ms;
  }

  &.active:after,
  &:hover:after {
    opacity: 1;
  }

  &[data-css-first] {
    left: 0px;
  }

   &:not([data-css-first]) { 
    right: -14px;
  }
`

export const LaneButton = styled(BaseLaneButton)`
  animation: ${show} 600ms;
  animation-fill-mode: both;
  animation-delay: 600ms;
`
