import BaseOverlay from "components/Overlay"
import styled, { keyframes } from "styled-components"

const showAnimation = keyframes`
  from {
    opacity: 0; transform: translateX(-10px); }
  to {
    opacity: 1; transform: translateX(0)}
`

export const Overlay = styled(BaseOverlay)`
  display:flex;
  justify-content: center;
  width: 100%;
  overflow: auto;
`

export const Content = styled.div`
  position: relative;
  display: flex;
  max-width: 100%;
  padding: 30px 0;
  //width: 860px;
  margin: 0 auto;
  transform-origin: bottom center;
  animation: ${showAnimation} 300ms;
  animation-delay: 50ms;
  animation-fill-mode: both;
`

export const Card = styled.div`
  position: relative;
  display: flex;
  align-items: stretch;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: white;
  border: solid 1px var(--border-hard-color);
  border-radius: 8px 8px;
  box-shadow: 0 0 8px 0 rgba(176,201,250,0.29);

  &:before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: black;
    opacity: .6;
  }
`

export const Layout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  width: 100%;
`

Layout.Top = styled.div`
  flex: 0 0 auto;
  border-bottom: solid 1px var(--border-subdued-color);
`

Layout.Bottom = styled.div`
  flex: 1 1 auto;
  overflow: hidden;
`
