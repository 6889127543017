import React from "react"
import TaskIcon from "@material-ui/icons/Add"
import useToggle from "hooks/useToggle"
import Item, { NewItem } from "./Item"

import {
  Wrapper,
  Items,
  Actions,
  Action,
} from "./styles"

function TaskList ({
  onTaskAdd,
  onTaskEditBegin,
  onTaskEditEnd,
  onTaskUpdate,
  tasks,
}) {
  const editModeToggler = useToggle({
    on: onTaskEditBegin,
    off: onTaskEditEnd,
  })

  const addModeToggler = useToggle({
    on: onTaskEditBegin,
    off: onTaskEditEnd,
  })

  return (
    <Wrapper data-css-editing={editModeToggler.isOn || addModeToggler.isOn}>
      {(tasks.length > 0 || addModeToggler.isOn) && (
        <Items>
          {tasks.map(task => (
            <Item
              key={task.id}
              task={task}
              onTaskEditStart={editModeToggler.on}
              onTaskEditEnd={editModeToggler.off}
              onTaskUpdate={onTaskUpdate}
            />
          ))}
          {addModeToggler.isOn && (
            <NewItem
              onTaskAdd={onTaskAdd}
              onTaskEditEnd={addModeToggler.off}
              onTaskEditStart={addModeToggler.on}
            />
          )}
        </Items>
      )}
      {addModeToggler.isOff && (
        <Actions>
          <Action onClick={addModeToggler.on}>
            <TaskIcon />
          </Action>
        </Actions>
      )}
    </Wrapper>
  )
}

export default TaskList
