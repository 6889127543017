import styled, { keyframes } from "styled-components"

const show = keyframes`
  0% {
    opacity: 0; 
    transform: translateX(-10px); 
  }

  100% {
    opacity: 1; 
    transform: translateX(0);
  }
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 70px;
  padding: 10px 5px 10px 10px;
  font-weight: 600;
  color: var(--text-color);
  cursor: pointer;
  border-bottom: solid 1px var(--border-subdued-color);
  opacity: 0;
  transform: translate(-10px);
  animation: ${show} .4s 1;
  animation-fill-mode: forwards;

  &:hover {
    color: var(--text-color);
    background: var(--panel-background-color);
    transition: all 200ms;
  }

  &[data-css-active="true"] {
    background: var(--text-color);
    color: var(--panel-background-color);

    &:hover {
      background: var(--text-color);
      color: var(--panel-background-color);
    }
  }
`

export const Ticker = styled.div`
  font-size: 16px;
  font-weight: inherit;
  line-height: 20px;
  text-transform: uppercase;
`

export const Label = styled.div`
  margin-top: auto;
  overflow: hidden;
  font-size: 10px;
  font-weight: inherit;
  line-height: 10px;
  text-align: left;
  text-overflow: ellipsis;
`
