import styled, { keyframes } from "styled-components"
import { Card as BaseCard } from "components/Card"

const show = keyframes`
  0% {
    opacity: 0; 
    transform: translateX(-5px); 
  }

  100% {
    opacity: 1; 
    transform: translateX(0);
  }
`

export const Wrapper = styled.div`
  min-width: 300px;
  position: relative;

  &[data-css-type="group"] {
    transition: all 200ms;
    border-bottom-left-radius: 8px;
    border-left: 1px solid;
    border-bottom: 1px solid;
    border-color: var(--border-subdued-color);

    &:hover {
      border-color: var(--border-hard-color);;
    }
  }

  animation: ${show} 300ms;
  animation-fill-mode: both;
  animation-delay: var(--animation-delay);
`

export const Card = styled(BaseCard)`
  margin-left: -1px; /* offset to accomodate for Wrapper border */
  overflow: hidden;
  font-size: 50px;

  &[data-css-type="group"],
  &[data-css-editing="true"] {
    background: var(--panel-subdued-background-color);
    overflow: visible;
  }

  &[data-css-type="group"] {
    border-radius: 0;
    position: sticky;
    top: calc(var(--level,0) * 60px - 31px);
    z-index: calc(10 - var(--level));
  }

`

export const Children = styled.div`
  padding: 20px 0 20px 20px;
`

export const Between = styled.div`
  display: flex;
  align-items: center;
  height: 20px;

  &:after {
    display: block;
    flex: 1 1 auto;
    height: 0px;
    content: '';
    border-bottom: solid 1px #D4E0F4;
    box-shadow: none;
    opacity: 0;
    transition: all 200ms;
    transition-delay: 200ms;
    transform: scaleX(0);
  }

  &.active:after,
  &:hover:after {
    opacity: 1;
    transform: scaleX(1);
  }

  &[data-css-top="true"] {
    margin-top: -20px
  }

  &[data-css-last-in-group="true"] {
    margin-bottom: -20px;
  }
`
