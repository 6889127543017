// TODO: Cleanup
import React from "react"
import ReactDOM from "react-dom"

import { Wrapper } from "./styles"

class Overlay extends React.Component {
  constructor (props) {
    super(props)
    this.el = document.createElement("div")
    this.wrapperRef = React.createRef()
    this.modalRoot = document.getElementById("modal-root")
    this.state = {
      open: false,
    }
  }

  componentDidMount () {
    this.modalRoot.appendChild(this.el)

    this.setState({
      open: true,
    })
  }

  componentWillUnmount () {
    this.modalRoot.removeChild(this.el)
  }

  handleClick = (e) => {
    const { onClose, onHide } = this.props
    if (e.target === this.wrapperRef.current) {
      if (onClose) onClose(e)
      if (onHide) onHide(e)
    }
  }

  render () {
    const {
      children,
      className,
      darken,
      pointerEvents,
    } = this.props

    const { open } = this.state

    return ReactDOM.createPortal(
      (
        <Wrapper
          ref={this.wrapperRef}
          darken={open && darken}
          className={className}
          pointerEvents={pointerEvents}
          onClick={this.handleClick}
        >
          {children}
        </Wrapper>
      ),
      this.el,
    )
  }
}

export default Overlay
