import React from "react"
import Item from "../Item"
import { Wrapper } from "./styles"

function Items ({
  activeId,
  level = 0,
  filter,
  onItemClick,
  onAspectAdd,
  onAspectUpdate,
  onAspectBeginEdit,
  aspects = [],
}) {
  return (
    <Wrapper>
      {aspects.map(aspect => (
        <Item
          activeId={activeId}
          SubTreeElement={Items}
          aspect={aspect}
          filter={filter}
          key={aspect.id}
          onItemClick={onItemClick}
          onAspectAdd={onAspectAdd}
          onAspectUpdate={onAspectUpdate}
          onAspectBeginEdit={onAspectBeginEdit}
        />
      ))}
    </Wrapper>
  )
}

export default Items
