import styled from "styled-components"

export const Wrapper = styled.div`
  display: flex;
  align-items: stretch;
  font-size: 14px;
`

export const Items = styled.div`
  display: flex;
  align-content: center;
  align-items: stretch;
`

export const Item = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 20px;
  border-left: solid 1px transparent;
  border-right: solid 1px transparent;
  color: var(--text-subdued-color);
  cursor: default;

  &:first-child {
    padding-left: 30px;
  }

  &:hover {
    text-decoration: underline;
  }

  &[data-css-active='true'] {
    background: white;
    color: var(--text-color);
    border-right: solid 1px var(--border-subdued-color);

    &:not(:first-child) {
      border-left: solid 1px var(--border-subdued-color)
    }

    &:hover {
      text-decoration: none;
    }

  }
`
