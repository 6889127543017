/* eslint-disable react/jsx-props-no-spreading */
import React from "react"
import { Wrapper, Layout } from "./styles"
import Breadcrumb from "./Breadcrumb"
import Tabs from "./Tabs"
import IconItem, { IconItems, ItemGroup } from "./IconItem"

function Header ({
  actions = [],
  breadcrumbs = [],
  tabs = [],
}) {
  return (
    <Wrapper data-css-has-tabs={tabs?.length > 0}>
      <Layout>
        <Layout.Left>
          {breadcrumbs.length > 0 && (
            <Breadcrumb items={breadcrumbs} />
          )}
          {tabs.length > 0 && (
            <Tabs items={tabs} />
          )}
        </Layout.Left>
        <Layout.Right>
          <IconItems>
            {actions.filter(aog => aog.enabled !== false).map((actionsOrGroup, i) => (
              <ItemGroup key={i}>
                {([].concat(actionsOrGroup)
                  .filter(aog => aog.enabled !== false)
                  .map(action => (
                    <IconItem {...action} key={action.id} />
                  ))
                )}
              </ItemGroup>
            ))}
          </IconItems>
        </Layout.Right>
      </Layout>
    </Wrapper>
  )
}

export default Header
