import styled from "styled-components"

export const Wrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 10px;
  align-items: center;
  cursor: default;
  transition: all 200ms;

  & {
    ${p => p.onClick && `
      &:hover {
        background: var(--panel-hover-color);
        border-radius: 100%;
      }

      &:active {
        transform: scale(.9);
      }
    `}
  }
`

export const Label = styled.div` `
export const IconWrapper = styled.div`
  svg {
    vertical-align: top;
  }
`

export const IconItems = styled.div`
  display: flex;
  font-size: 14px;
`

export const ItemGroup = styled.div`
  display: flex;
  grid-auto-flow: column;
  grid-gap: 20px;
  align-items: stretch;
  padding: 0 20px;

  &:not(:first-child) {
    border-left: solid 1px var(--border-subdued-color);
  }
`
