import styled, { keyframes } from "styled-components"

const showAnimation = keyframes`
  from {
    opacity: 0; 
  }
  to {
    opacity: 1; 
  }
`

export const MenuButtonWrapper = styled.div`
  position: absolute;
  top: 6px;
  right: 6px;
`

export const Wrapper = styled.div`
  position: relative;
  justify-content: flex-end;
  width: 60px;
  height: 60px;
  overflow: hidden;
  vertical-align: middle;
  background: var(--panel-subdued-background-color);
  background-size: cover;
  border-radius: 3px;

  &[data-css-size="auto"] {
    width: auto;
    max-width: 100%;
    height: auto;
  }

  &[data-css-aspect="square"] {
    aspect-ratio: 1
  }
`

export const ImageWrapper = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    animation: ${showAnimation} 300ms;
  }
`

export const Star = styled.div`
  position: absolute;
  bottom: 4px;
  left: 4px;
  width: 20px;
  height: 20px;
  color: var(--panel-background-color);

  svg {
    font-size: 20px;
  }
`
