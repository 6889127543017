import React, { useRef, useState } from "react"
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline"
import PublishIcon from "@material-ui/icons/Publish"
import GetAppIcon from "@material-ui/icons/GetApp"
import DeleteIcon from "@material-ui/icons/Delete"
import StarsIcon from "@material-ui/icons/Stars"
import DeleteSweepIcon from "@material-ui/icons/DeleteSweep"
import ArchiveOutlinedIcon from "@material-ui/icons/ArchiveOutlined"
import AttachFileIcon from "@material-ui/icons/AttachFile"
// import VerticalAlignTopIcon from "@material-ui/icons/VerticalAlignTop";
// import VerticalAlignBottomIcon from "@material-ui/icons/VerticalAlignBottom";
import Overlay from "components/Overlay"

import {
  Wrapper,
  InnerWrapper,
  Title,
  Items,
  Item,
  ItemGroups,
  ItemGroup,
} from "./styles"

const iconMap = {
  add: AddCircleOutlineIcon,
  eye: AddCircleOutlineIcon,
  addBefore: PublishIcon,
  addAfter: GetAppIcon,
  remove: DeleteIcon,
  archive: ArchiveOutlinedIcon,
  removeAll: DeleteSweepIcon,
  archiveAll: DeleteSweepIcon,
  star: StarsIcon,
  paperclip: AttachFileIcon,
}

function Tooltip ({
  anchor,
  children,
  items: maybeItemsOrItemsFunc = [],
  title,
  onShow,
  onHide,
  itemProps,
  darken,
}) {
  const ref = useRef()
  const [visible, setVisible] = useState(false)

  if (!maybeItemsOrItemsFunc || maybeItemsOrItemsFunc.length === 0) return children || null

  const rect = ref.current ? ref.current.getBoundingClientRect() : {}

  const items = typeof maybeItemsOrItemsFunc === "function" ? maybeItemsOrItemsFunc(itemProps) : maybeItemsOrItemsFunc

  const groups = items.length > 0 && Array.isArray(items[0]) ? items : [items]

  const hide = () => {
    setVisible(false)
    if (onHide) onHide()
  }

  const show = () => {
    setVisible(true)
    if (onShow) onShow()
  }

  const getStyle = () => {
    if (anchor === "topRight") {
      return {
        right: `${rect.right}px`,
        top: `${rect.top}px`,
      }
    }

    if (anchor === "topLeft") {
      return {
        left: `${rect.left}px`,
        top: `${rect.top}px`,
      }
    }

    if (anchor === "mouseX") {
      return {
        left: `${window.currentMouseX}px`,
        top: `${rect.top + (rect.height / 2)}px`,
      }
    }

    if (anchor === "mouseY") {
      return {
        left: `${rect.left + (rect.width / 2)}px`,
        top: `${window.currentMouseY}px`,
      }
    }

    if (anchor === "mouse") {
      return {
        left: `${window.currentMouseX}px`,
        top: `${window.currentMouseY}px`,
      }
    }
    // return {
    // left: `${rect.left + (rect.width / 2)}px`,
    // top: `${rect.top + (rect.height / 2)}px`,
    // };
    return {
      left: `${rect.left + (rect.width / 2)}px`,
      top: `${rect.top + (rect.height / 2)}px`,
      transform: "translateX(0%)",
    }
  }

  const handleItemClick = (item) => {
    // setTimeout(() => {
    if (item.onClick) {
      item.onClick()
    }
    hide()
    // }, 200);
  }

  return (
    <>
      <>
        {(() => {
          const props = {
            onClick: () => (!visible ? show() : hide()),
            active: visible,
            menuActive: visible,
            showMenu: () => (!visible ? show() : hide()),
            "data-css-active": visible,
            "data-css-popup-active": visible,
            ref,
          }

          if (typeof children === "function") {
            return children(props)
          }

          return React.cloneElement(children, {
            ref,
            ...props,
            className: visible ? "active" : "",
          })
        })()}
      </>

      {visible && (
        <Overlay
          darken={darken || false}
          onHide={hide}
        >
          <Wrapper style={getStyle()} anchor={anchor}>
            <InnerWrapper>
              {title && <Title>{title}</Title>}
              <ItemGroups>
                {groups.map(group => (
                  <ItemGroup key={group.map(i => i.label).join("")}>
                    <Items>
                      {group.map(({ Icon, icon, ...item }) => (
                        <Item
                          key={item.label}
                          onClick={() => handleItemClick(item)}
                        >
                          {(() => {
                            const IconComponent = iconMap[icon] || Icon || "span"

                            return (
                              <Item.Icon rotate={item.rotate}>
                                <IconComponent />
                              </Item.Icon>
                            )
                          })()}
                          <Item.Label>{item.label}</Item.Label>
                        </Item>
                      ))}
                    </Items>
                  </ItemGroup>
                ))}
              </ItemGroups>
            </InnerWrapper>
          </Wrapper>
        </Overlay>
      )}
    </>
  )
}

export default Tooltip
