import React from "react"
import * as Yup from "yup"
import gql from "graphql-tag"
import { Form } from "components/Form"
import { Formik } from "formik"
import { Textfield } from "components/Form/Textfield/formik"
import { useMutation } from "@apollo/client"
import { SectionHeader } from "../../styles"

const UPDATE_PASSWORD_MUTATION = gql`
  mutation UpdateAccount($password: String!) {
    updateAccount(password: $password) {
      firstName
      lastName
      email
    }
  }
`

const PasswordSchema = Yup.object().shape({
  password: Yup.string()
     .min(6, "Too Short!")
     .max(70, "Too Long!")
     .required("Required"),

  passwordConfirm: Yup.string().when("password", {
    is: val => (!!(val && val.length > 0)),
    then: Yup.string().required("Required").oneOf(
      [Yup.ref("password")],
      "Both password need to be the same"
    ),
  }),
})

function Security ({
  onSubmit: handleSubmit,
}) {
  const [updatePassword, { error: updatePasswordError }] = useMutation(
    UPDATE_PASSWORD_MUTATION,
    { refetchQueries: ["GetCurrentUser"] }
  )

  return (
    <>
      <SectionHeader>Update Password</SectionHeader>
      <Formik
        validateOnBlur={false}
        validationSchema={PasswordSchema}
        initialValues={{
          password: "",
          passwordConfirm: "",
        }}
        onSubmit={(variables, { setStatus }) => {
          updatePassword({ variables })
               .catch((error) => {
                 setStatus(error.message)
               }).then((result) => {
                 handleSubmit(result)
               })
        }}
      >
        {({ errors }) => (
          <Form>
            {updatePasswordError && updatePasswordError.message}
            <Form.Rows>
              <Form.Row>
                <Form.Col>
                  <Textfield
                    name="password"
                    type="password"
                    error={errors.password}
                    placeholder="New Password"
                    autoFocus
                  />
                </Form.Col>
              </Form.Row>
              <Form.Row>
                <Form.Col>
                  <Textfield
                    type="password"
                    name="passwordConfirm"
                    error={errors.passwordConfirm}
                    placeholder="Confirm New Password"
                  />
                </Form.Col>
              </Form.Row>
            </Form.Rows>
            <Form.Actions>
              <Form.Action>
                <Form.Button type="submit">Update</Form.Button>
              </Form.Action>
            </Form.Actions>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default Security
