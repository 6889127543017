import React, { useContext } from "react"
import PopMenu from "components/PopMenu"
import MoreVert from "@material-ui/icons/MoreVert"
import Choice from "components/inputs/Choice"
import { Context as AppContext } from "AppContext"

import {
  Wrapper,
  Layout,
  Actions,
  Action,
  TextField,
} from "./styles"

function Item ({
  onTaskEditEnd,
  onTaskEditStart,
  task,
}) {
  const { actions, menuOptions } = useContext(AppContext)

  return (
    <Wrapper>
      <Layout>
        <Layout.Left>
          <Choice
            checked={task.completed}
            onClick={() => actions.task.updateVars({
              id: task.id,
              completed: !task.completed,
            })}
          />
        </Layout.Left>
        <Layout.Main>
          <TextField
            value={task.description}
            strike={!!task.completed}
            onCancel={() => onTaskEditEnd(task)}
            onBlur={() => onTaskEditEnd(task)}
            onFocus={() => onTaskEditStart(task)}
            onSubmit={(description) => actions.task.updateVars({
              id: task.id,
              description,
            })}
            multiline
          />
        </Layout.Main>
        <Layout.Right>
          <Actions>
            <PopMenu items={[menuOptions.archiveTask(task)]}>
              <Action>
                <MoreVert />
              </Action>
            </PopMenu>
          </Actions>
        </Layout.Right>
      </Layout>
    </Wrapper>
  )
}

export default Item
