import styled, { keyframes } from "styled-components"
import Textfield from "components/inputs/Textfield"
import { Wrapper as Lane } from "../styles"

const show = keyframes`
  0% {
    opacity: 0; 
  }

  100% {
    opacity: 1; 
  }
`

export const Wrapper = styled.div`
  height: 50px;

  /* TODO: on re-renders this messes up, fix */
  ${/* sc-prop */ Lane}:first-child & {
    //padding-left: 20px;
  }

  ${/* sc-prop */ Lane}:not(:last-child) & {
    border-right: solid 1px var(--border-subdued-color);
  }

  background: var(--panel-subdued-background-color);
`

export const Inner = styled.div`
  display: flex;
  align-items: center;
  height: 50px;
  padding: 0 15px 0 20px;
  font-size: 16px;
  font-weight: normal;
  line-height: 50px;
  color: var(--text-color);
  border-bottom: solid 1px #D4E0F4;
  transition: all 200ms;

  svg {
    font-size: 20px;
    vertical-align:top;
  }

  /*
  animation: ${show} 200ms;
  animation-fill-mode: both;
  animation-delay: 200ms;
  */
`

export const LabelTextField = styled(Textfield)`
  flex-grow: 1;
  height: 100%;
  margin-left: 10px;
  font-size: 16px;
`

export const Actions = styled.div`
  display: flex;
`

export const Action = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 30px;
  margin-left: 10px;
  line-height: 30px;
  border-radius: 4px;
  opacity: 0;
  transition: opacity 200ms;

  ${Wrapper}:hover & {
    opacity: 1;
  }

  &[data-css-popup-active="true"],
  &:hover {
    background: var(--panel-hover-color);
    opacity: 1;
  }

  svg {
    font-size: 16px;
  }
`
