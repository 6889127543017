import React, { forwardRef, useContext } from "react"
import FolderIcon from "@material-ui/icons/FolderOutlined"
import FolderSpecialOutlinedIcon from "@material-ui/icons/FolderSpecialOutlined"
import MoreVert from "@material-ui/icons/MoreVert"
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined"
import PopMenu from "components/PopMenu"
import Tooltip from "components/Tooltip"
import { Context } from "AppContext"

import {
  Wrapper,
  LabelTextField,
  Actions,
  Action,
  Inner,
} from "./styles"

const Heading = forwardRef(({ aspect }, ref) => {
  const {
    actions,
    menuOptions,
    navigation,
  } = useContext(Context)

  return (
    <Wrapper>
      <Inner>
        {aspect.type === "virtualGroup" && (
          <FolderSpecialOutlinedIcon />
        )}
        {aspect.type === "group" && (
          <FolderIcon />
        )}
        {aspect.type === "group" && (
          <LabelTextField
            value={aspect.label}
            ref={ref}
            editable={aspect.type === "group"}
            onClick={() => navigation.aspectDetail(aspect)}
            onSubmit={(label) => actions.aspect.updateVars({
              id: aspect.id,
              label,
            })}
            doubleClickToEdit
          />
        )}
        {aspect.type !== "group" && (
          <LabelTextField
            value={aspect.label}
            editable={false}
          />
        )}
        <Actions>
          {aspect.type === "group" && (
            <PopMenu items={[menuOptions.archiveAspect(aspect)]}>
              <Action>
                <MoreVert />
              </Action>
            </PopMenu>
          )}
        </Actions>
        {aspect.type === "virtualGroup" && (
          <Tooltip
            items={[{
              title: "Virtual Group",
              description: `
              Ungrouped Aspects retain their order within their parent aspect but don't belong to a group.
            `,
            }, {
              description: `
              Any ungroup aspects positioned sequentially will appear within the same "Virtual Group" lane.
            `,
            }]}
          >
            <InfoOutlinedIcon />
          </Tooltip>
        )}
      </Inner>
    </Wrapper>
  )
})

export default Heading
